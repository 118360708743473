import styled from "styled-components";

// Utils
import { Color, Font, rem, responsive, rgba } from "../../utils/style";
import Text from "../Text";

const FormulaHighlightsContainer = styled.div`
  width: 100%;
  margin: 16px auto 12px;
  display: flex;
  flex-wrap: wrap;

  ${responsive.md`
    margin: 24px 0 8px;
  `}
`;

const ServingSize = styled.div`
  width: 100%;
  font-size: ${rem(14)};
  margin-bottom: 4px;
`;

const Highlights = styled.div`
  width: 100%;
  margin: 4px auto;
  display: flex;
  flex-wrap: nowrap;

  p {
    font-size: ${rem(11)};
    line-height: ${rem(20)};
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin-right: 12px;
    margin-bottom: 4px;
    position: relative;
    text-align: center;

    ${responsive.md`
      font-size: ${rem(14)};
      line-height: ${rem(23)};
      letter-spacing: 0.9px;
      margin-right: 16px;
    `}
    &:first-of-type {
      text-align: left;
    }

    &:last-of-type {
      text-align: right;
    }

    span {
      text-transform: none;
      color: ${Color.ritualBlue};
      font-family: ${Font.circular};
      font-size: ${rem(16)};
      font-weight: 500;
      letter-spacing: 0;
      line-height: ${rem(28)};

      &:nth-child(2) {
        margin-left: 3px;
        font-size: ${rem(10)};
        letter-spacing: 0.67px;
        line-height: ${rem(18)};
        text-transform: uppercase;
      }

      ${responsive.md`
          font-size: ${rem(18)};

          &:nth-child(2) {
            margin-left: 6px;
          }
      `}
    }
  }

  p {
    border-right: 1px solid ${rgba(Color.ritualBlue, 0.16)};
    padding-right: 12px;

    // TODO: Update Design for XS(320) Screen Size
    @media (max-width: 335px) {
      margin-right: 10px;
      padding-right: 10px;

      span {
        font-size: ${rem(14)};

        &:nth-child(2) {
          font-size: ${rem(10)};
          margin-left: 3px;
        }
      }
    }

    ${responsive.md`
      padding-right: 16px;
    `}
  }

  p:last-of-type {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }
`;

export default function FormulaHighlights(props) {
  const product = props.product;
  const { servingSizeWeight } = product.supplementFacts;
  return (
    <FormulaHighlightsContainer>
      <ServingSize>
        <Text
          defaultMessage={`Per serving ({value}):`}
          id={"product.protein.per-serving-weight"}
          values={{
            value: servingSizeWeight,
          }}
        />
      </ServingSize>
      <Highlights>
        {product.formulaHighlights.map((highlight, index) => (
          <p key={`${highlight.label}-${index}`} data-testid="highlight">
            <span>{highlight.value}</span>
            <span>{highlight.label}</span>
          </p>
        ))}
      </Highlights>
    </FormulaHighlightsContainer>
  );
}
