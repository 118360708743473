import styled from "styled-components";
import {
  getContentfulProductForPlan,
  getPlanForProductSku,
} from "../../utils/planToProduct";
import { Font, Opacity, rem, responsive } from "../../utils/style";

const ProductSelectorWrapper = styled.div`
  margin: 0 0 32px;
  display: flex;

  ${responsive.sm`
    margin: 0 0 32px;
  `};
`;

const ProductButton = styled.button`
  ${Font.circular}
  border-radius: 8px;
  background: #fff;
  border: ${(props) =>
    props.active
      ? "2px solid rgb(20, 43, 111)"
      : `2px solid rgba(20, 43, 111, ${Opacity.light})`};
  color: ${(props) =>
    props.active ? "rgb(20, 43, 111)" : `rgba(20, 43, 111, ${Opacity.light})`};

  height: 40px;
  width: 100%;

  transition: 250ms;
  font-size: ${rem(14)};
  font-weight: 500;
  letter-spacing: 0px;

  ${responsive.sm`
    width: auto;
    height: auto;
    padding: 0 24px;
  `}

  ${responsive.md`
    height: 42px;
    font-size: ${rem(16)};
  `}

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  &:not(:last-of-type) {
    margin-right: 16px;
  }
`;

const ScentSelector = ({ products, selectedProduct, onClick }) => {
  if (!products || products.length < 2) return null;

  return (
    <ProductSelectorWrapper>
      {products?.map((product, index) => {
        if (!product || !product.sku) return null;

        const plan = getPlanForProductSku(product.sku);

        if (!plan) return null;

        const contentfulProduct = getContentfulProductForPlan(plan.id);

        return (
          <ProductButton
            active={selectedProduct.sku === product.sku}
            key={index}
            onClick={() => onClick(contentfulProduct)}
          >
            {plan.variantDescription}
          </ProductButton>
        );
      })}
    </ProductSelectorWrapper>
  );
};

export default ScentSelector;
