import { useState } from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Utils
import { Color, rem, responsive } from "../../../utils/style";

// Components
import Container from "../../Container";
import ScrollableOverflow from "../../ScrollableOverflow";
import { Icons } from "../../../utils/react-svg";
import ProteinIngredientStoryModal from "./ProteinIngredientStoryModal";

const PlusIcon = Icons.Plus;
const IMAGE_HEIGHT = "133px";

const StoriesContainer = styled(Container)`
  margin-bottom: 80px;

  > div:first-child {
    ${responsive.sm`
      text-align: center;
    `}
  }

  ${responsive.md`
    margin-bottom: 120px;
  `}

  .scrollable-overflow-draggable-content {
    white-space: normal;
  }
`;
const MODEL_BUTTON_HEIGHT = "48px";
const StoryContainer = styled.div`
  height: 100%;
  margin-right: 16px;
  position: relative;

  ${responsive.sm`
    margin-right: 20px;
  `}

  > button {
    padding: 0;
    border: 0;
    background: transparent;

    width: 212px;
    ${responsive.sm`
      width: 220px;  
    `}

    ${responsive.lg`
      width: 270px;
    `}

    &:hover {
      img {
        transform: scale(1.1);
      }
    }

    img {
      transition: transform 0.2s ease-in !important;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  ${responsive.md`
    margin-right: 26px;
  `}
  ${responsive.lg`
    margin-right: 29px;
  `}
  
  .story-title {
    color: ${Color.ritualBlue};
    text-align: left;
    font-size: ${rem(18)};
    font-weight: 500;
    letter-spacing: 0;
    line-height: ${rem(28)};
    margin-top: 8px;
  }

  .protein-story-icon-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: calc(${IMAGE_HEIGHT} - ${MODEL_BUTTON_HEIGHT} - 16px);
    left: 16px;
    background: #fff;
    width: ${MODEL_BUTTON_HEIGHT};
    height: ${MODEL_BUTTON_HEIGHT};
    border-radius: 50%;
  }
`;

const ImgStyled = styled(GatsbyImage)`
  width: 100%;
  height: ${IMAGE_HEIGHT};
  background-color: ${Color.ritualGrey};
  user-select: none;
  -webkit-user-drag: none;
  pointer-events: none;
  -webkit-touch-callout: none;
`;

const getInitialClosedState = (ingredientStoryContent) =>
  Array(ingredientStoryContent.length).fill(false);

export default function ProteinIngredientStories(props) {
  const { ingredientStoryImages, ingredientStoryContent } = props.ingredient;
  if (!ingredientStoryContent || !ingredientStoryContent.length) {
    return null;
  }

  const initialState = getInitialClosedState(ingredientStoryContent);
  const [isOpenModalStatuses, setIsOpenModalStatuses] = useState(initialState);

  return (
    <StoriesContainer>
      <ScrollableOverflow bleedLeft={true} bleedRight={true}>
        {ingredientStoryContent.map((ingredientStory, index) => (
          <StoryContainer key={ingredientStory.id}>
            <button
              onClick={() => {
                const newIsOpenModalStatuses = [...isOpenModalStatuses];
                newIsOpenModalStatuses[index] = true;

                setIsOpenModalStatuses(newIsOpenModalStatuses);
              }}
            >
              <ImgStyled
                image={getImage(ingredientStoryImages[index])}
                alt={ingredientStoryImages[index].title}
              />
              <div className={"story-title"}>{ingredientStory.title}</div>
              <div className={"protein-story-icon-placeholder"}>
                <PlusIcon />
              </div>
            </button>
            <ProteinIngredientStoryModal
              activeStory={index}
              isOpen={isOpenModalStatuses[index]}
              onRequestClose={() => {
                setIsOpenModalStatuses(
                  getInitialClosedState(ingredientStoryContent),
                );
              }}
              ingredient={props.ingredient}
            />
          </StoryContainer>
        ))}
      </ScrollableOverflow>
    </StoriesContainer>
  );
}
