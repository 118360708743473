import * as React from "react";
import styled, { css } from "styled-components";

import { ActionType } from "../../constants/product";

// Redux
import { connect } from "react-redux";
import cartProductSelectors from "../../store/cart-product/selectors";
import productSelectors from "../../store/product/selectors";

// Components
import ProductSelector from "../bundle/ProductSelector";
import Container from "../Container";
import Ribbon from "../global/Ribbon";
import Row from "../Row";
import Text from "../Text";
import FormulaHighlights from "./FormulaHighlights";
import ActionEyebrow from "./hero/ActionEyebrow";
import ProductName from "./hero/ProductName";
import ProductOfferUpsell from "./hero/ProductOfferUpsell";
import ShakerPromo from "./hero/ShakerPromo";
import SubscriptionBenefits from "./hero/SubscriptionBenefits";
import ObgynClaim from "./ObgynClaim";
import ProbioticBlend from "./ProbioticBlend";
import ProductCta from "./ProductCta";
import ProductHeroCarousel from "./ProductHeroCarousel";
import ScentSelector from "./ScentSelector";
import StarRating from "./StarRating";
import SupplementFacts from "./SupplementFacts";
import ProductStudyModal from "./templates/ProductStudyModal";

// Utils
import { variation } from "../../services/launchDarkly";
import metrics from "../../utils/metrics";
import { getPromotionDetails } from "../../utils/promotion";
import { Color, media, Opacity, rem, responsive } from "../../utils/style";

// Hooks
import useDiscount from "../../hooks/discount/useDiscount";
import useProductSpecificPromotion from "../../hooks/discount/useProductSpecificPromotion";
import useShoppingIntention from "../../hooks/useShoppingIntention";

const HeroArea = styled.section.attrs({ id: "product-hero" })`
  background-color: ${(p) => p.backgroundColor || "#EEECF5"};
  width: 100%;
  cursor: initial;
`;

const StyledContainer = styled(Container).attrs({
  id: "product-hero_container",
  className: "container",
})`
  height: 100%;
  overflow: visible !important;
  margin-top: 52px;

  ${responsive.sm`
    margin-bottom: 24px;
  `}

  ${responsive.md`
    margin-top: 68px;
    margin-bottom: 24px;
  `}
`;

const StyledRow = styled(Row).attrs({
  id: "product-hero_container_row",
  className: "row",
})`
  height: 100%;

  ${responsive.sm`
    padding-top: 24px;
  `}

  ${responsive.md`
    padding-top: 40px;

    hr {
      width: 100%;
      margin: 0;
    }
  `}
`;

const ContentArea = styled.div.attrs({
  id: "product-hero_container_row_content",
  className: "col-12 col-sm-5",
})`
  display: flex;
  flex-direction: column;

  order: 0;
  padding-left: 0;

  ${media.mobile`
    order: 1;
    padding: 24px 24px 16px;
  `}

  ${responsive.md`
    padding-right: 0;
  `};

  ${responsive.lg`
    justify-content: center;
    padding-right: 15px;
  `}
`;

const Divider = styled.hr`
  background: ${Color.fadedGrey};
  height: 1px;
  border: none;
  margin: 32px 0 0;
`;

const ImageArea = styled.div.attrs({
  id: "product-hero_container_row_image",
  className: "col-12 col-sm-6 offset-sm-1",
})`
  order: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${responsive.sm`
    order: 1;
    padding-left: 0;
    justify-content: start;
  `}

  ${responsive.md`
    justify-content: space-between;
  `}
`;
const ProductDescription = styled.div`
  margin-top: 16px;

  ${responsive.md`
    margin-top: 24px;
  `}
  p {
    margin: 0;
    font-weight: 300;
    letter-spacing: 0px;
    font-size: ${rem(14)};
    line-height: ${rem(24)};

    ${responsive.md`
      font-size: ${rem(16)};
      line-height: ${rem(26)};
    `}
  }
`;

const LinkContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 32px;

  ${responsive.sm`
    text-wrap: nowrap;
  `}

  ${responsive.md`
    margin-top: 16px;
    margin-bottom: 40px;
  `}
`;

const LinkButton = styled.button`
  margin-right: 24px;

  ${responsive.md`
    margin-right: 32px;
  `}

  &:last-of-type {
    margin-right: 0;
  }

  appearance: none;
  border: none;
  background: none;

  display: inline-block;
  padding: 0;

  font-weight: 500;
  letter-spacing: 0px;
  font-size: ${rem(14)};
  line-height: ${rem(24)};

  ${responsive.md`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
  `}

  color: ${Color.ritualBlue};
  border-bottom: 2px solid ${Color.ritualBlue};
  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  &:hover {
    opacity: ${Opacity.light};
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${responsive.sm`
    justify-content: flex-start;
  `}
`;

const CarouselWrapper = styled.div`
  position: relative;
  margin: 0 9px;

  ${responsive.sm`
    margin: 0;
  `}
`;

const ribbonContentStyle = css`
  margin-bottom: 16px;
`;

export const ProductHeroComonent = class ProductHero extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reviewsLinkActive: true,
      showReviews: false,
      isSupplementFactsOpen: false,
      isStudyModalOpen: false,
    };

    this.isProteinProduct = this.props.productData.subCategory === "Protein";
    this.factsPopup = React.createRef();
    this.openStudyModal = this.openStudyModal.bind(this);
    this.closeModals = this.closeModals.bind(this);
  }

  componentDidMount() {
    const showReviews = variation("pdp-hero-reviews-star-rating");

    this.setState({
      showReviews,
    });
  }

  appendAccessibleFootnoteLink() {
    // include link to footer for a11y
    const { headerDescription } = this.props;
    const htmlHeaderDescription = `<p>${headerDescription}</p>`;
    const accessibleFooterLinkHtml =
      '<a aria-describedby="disclaimer-footnote-label" href="#disclaimer-footnote" id="footnote-ref">*</a>';

    return htmlHeaderDescription.replace("*", accessibleFooterLinkHtml);
  }

  openSupplementFacts() {
    metrics.track("Overlay Opened", {
      location: "PDP Hero",
      title: "Supplement Facts",
    });

    this.setState({
      isSupplementFactsOpen: true,
    });
  }

  openStudyModal(e) {
    metrics.track("Overlay Opened", {
      location: "PDP Hero",
      title: e.target.innerText,
    });

    this.setState({
      isStudyModalOpen: true,
    });
  }

  closeModals() {
    this.setState({
      isSupplementFactsOpen: false,
      isStudyModalOpen: false,
    });
  }

  renderProductSelector({
    product,
    showProductSelector,
    selectorProducts,
    selectorLabels,
    setSelectedProduct,
    showCta = true,
  }) {
    if (!showProductSelector || !showCta) return null;

    return (
      <ProductSelector
        products={selectorProducts}
        labels={selectorLabels}
        selectedProduct={product}
        setSelectedProduct={setSelectedProduct}
        setSelectedPlan={() => {}}
      />
    );
  }

  renderScentSelector(props) {
    const {
      allProducts,
      product,
      handleSelectedProduct,
      selectedProduct,
      showCta = true,
    } = props;

    if (!showCta) return null;

    const productForContentfulProduct = allProducts.find(
      (p) => p.sku === product.sku,
    );
    const allProductsFromGroup = allProducts.filter(
      (p) => p.productGroupId === productForContentfulProduct.productGroupId,
    );

    return (
      <ScentSelector
        onClick={handleSelectedProduct}
        products={allProductsFromGroup}
        selectedProduct={selectedProduct}
      />
    );
  }

  renderCta() {
    const { selectedProduct, product, showCta = true } = this.props;

    if (!showCta) {
      return null;
    }
    return (
      <ButtonWrapper>
        <ProductCta
          {...this.props}
          product={selectedProduct ? selectedProduct : product}
        />
      </ButtonWrapper>
    );
  }

  mapStudyModalLink = (e) => {
    return this.props.productStudyModal ? this.openStudyModal(e) : null;
  };

  renderStudyModalLink() {
    const { productData, productStudyModal } = this.props;
    const productStudyLinkText = productStudyModal?.linkText;

    return (
      productStudyLinkText && (
        <LinkButton
          onClick={this.mapStudyModalLink}
          data-modal={
            `${productData.name} product study modal` || "product study modal"
          }
        >
          {productStudyLinkText}
        </LinkButton>
      )
    );
  }

  render() {
    const {
      product,
      actionType,
      heroBackgroundColor,
      featuredImages,
      zoomImages,
      showCarousel = true,
      reviewScore,
      reviewCount,
      showFormulaHighlights,
      productData,
      promoProduct,
      ribbonPromotion,
      showActionEyebrow = true,
      showProbioticBlend,
      showObgynClaim = false,
      selectedProduct,
      productStudyModal = null,
    } = this.props;
    const { scentSelector } = product;

    const { showReviews, isSupplementFactsOpen, isStudyModalOpen } = this.state;

    const headerDescriptionHtml = this.appendAccessibleFootnoteLink();

    return (
      <HeroArea
        backgroundColor={heroBackgroundColor}
        aria-labelledby="product-name"
      >
        <StyledContainer>
          <StyledRow>
            <ContentArea>
              {!!ribbonPromotion && actionType === ActionType.AddToCart && (
                <Ribbon contentStyle={ribbonContentStyle}>
                  <Text
                    id="product.hero.product-specific-promotion"
                    defaultMessage="Save {amount}"
                    values={{
                      amount:
                        getPromotionDetails(ribbonPromotion).formattedValue,
                    }}
                  />
                </Ribbon>
              )}
              {showActionEyebrow && (
                <ActionEyebrow product={product} actionType={actionType} />
              )}

              <ProductName product={product} />

              {showReviews && !!reviewScore && (
                <StarRating
                  readReviews={true}
                  score={reviewScore}
                  count={reviewCount}
                  productHero={true}
                />
              )}

              <ProductDescription
                dangerouslySetInnerHTML={{ __html: headerDescriptionHtml }}
              />

              {showProbioticBlend && <ProbioticBlend />}
              {showObgynClaim && <ObgynClaim />}

              {showFormulaHighlights && product.formulaHighlights && (
                <FormulaHighlights product={product} />
              )}

              <LinkContainer>
                <LinkButton onClick={this.openSupplementFacts.bind(this)}>
                  <Text
                    id="product.hero.supplement-facts"
                    defaultMessage="Supplement Facts"
                  />
                </LinkButton>

                {this.renderStudyModalLink()}
              </LinkContainer>

              {!scentSelector && this.renderProductSelector(this.props)}

              {scentSelector && this.renderScentSelector(this.props)}

              {this.renderCta()}

              <ShakerPromo
                className="d-block d-sm-none d-md-block mt-3"
                promoProduct={promoProduct}
                productData={productData}
              />

              <ProductOfferUpsell productSku={product.sku} />

              <div className={"w-100 d-block"}>
                <SubscriptionBenefits product={product} />
                <Divider className="d-block d-sm-none" />
              </div>
            </ContentArea>

            <ImageArea>
              <CarouselWrapper>
                <ProductHeroCarousel
                  sku={selectedProduct ? selectedProduct.sku : product.sku}
                  trimmed={!showCarousel}
                  zoomEnabled={showCarousel}
                  images={featuredImages}
                  zoomImages={zoomImages}
                />
              </CarouselWrapper>
              <ShakerPromo
                className="d-none d-sm-flex d-md-none mt-sm-6 mt-md-0"
                promoProduct={promoProduct}
                productData={productData}
              />
            </ImageArea>
          </StyledRow>
        </StyledContainer>

        <SupplementFacts
          supplementFacts={product.supplementFacts}
          isOpen={isSupplementFactsOpen}
          onRequestClose={this.closeModals}
        />

        {productStudyModal && (
          <ProductStudyModal
            productData={productData}
            productStudyModal={productStudyModal}
            isOpen={isStudyModalOpen}
            onRequestClose={this.closeModals}
          />
        )}
      </HeroArea>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    cartQuantity: cartProductSelectors.activeCartProductQuantity(state),
    allProducts: productSelectors.allProducts(state),
    // TODO Identify shaker product without SKU
    promoProduct: productSelectors.productForSku(state, { sku: "PROSHAKER" }),
  };
};

const withHooks = (Component) => (props) => {
  const shoppingIntention = useShoppingIntention();
  const actionType = props.actionType || shoppingIntention.actionType;
  const productPromotion = useProductSpecificPromotion(
    actionType,
    props.product,
  );
  const discount = useDiscount(actionType, 1, props.product);

  return (
    <Component
      {...props}
      ribbonPromotion={productPromotion}
      actionType={actionType}
      discount={discount}
    />
  );
};

export default withHooks(connect(mapStateToProps, {})(ProductHeroComonent));
