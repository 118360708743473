import { ComponentPropsWithRef } from "react";
import styled from "styled-components";
import { Font } from "../../utils/style";
import { Color, Typography } from "../../utils/styleDesignSystem";
import PulseIcon from "../animation/PulseIcon";

const Wrapper = styled.div`
  position: relative;
  margin-top: 19px;
  display: flex;
  align-items: flex-center;
  padding: 16px;
  gap: 12px;

  background: ${Color.yellowOchre20};

  color: ${Color.indigoBlue};
  ${Typography.body3}
  ${Font.circular}
`;

const Arrow = styled.div`
  position: absolute;
  height: 16px;
  width: 16px;
  background-color: ${Color.yellowOchre20};
  top: -8px;
  left: 48px;
  transform: rotateY(0deg) rotate(45deg);
`;

const Content = styled.div`
  margin: auto;

  p {
    margin: 0;
  }
`;

type TooltipProps = ComponentPropsWithRef<"div">;

const Tooltip = ({ children, ...rest }: TooltipProps) => (
  <Wrapper {...rest}>
    <Arrow />
    <PulseIcon />
    <Content>{children}</Content>
  </Wrapper>
);

export default Tooltip;
