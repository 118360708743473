import { Component } from "react";
import styled from "styled-components";
import $ from "jquery";
import ReviewStars from "../reviews/ReviewStars";

// Services
import intl from "../../services/intl";

// Utils
import { Font, rem, atMost, Opacity } from "../../utils/style";
import metrics from "../../utils/metrics";

const StarRatingWrapper = styled.div.attrs((p) => ({
  id: `${p.id}`,
  className: "d-inline-flex align-items-center",
}))`
  margin-top: 8px;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;

  .ReviewStars--star {
    transition: opacity 200ms ease-in-out;
  }

  &:hover {
    p,
    .ReviewStars--star {
      opacity: ${(p) => (p.productHero ? Opacity.light : 1)};
    }
  }
`;

const StarCount = styled.div.attrs((p) => ({
  id: `${p.id}_star-count`,
  className: "d-flex",
}))`
  .ReviewStars--star {
    width: 15px;
    height: 14px;
    margin-right: 0;
  }
`;

const ReviewCount = styled.p.attrs((p) => ({
  id: `${p.id}_review-count`,
  className: "d-flex m-0 ml-2",
}))`
  ${Font.circular};
  font-weight: bold;
  transition: opacity 200ms ease-in-out;

  font-size: ${rem(14)};
  line-height: ${rem(24)};

  &.-read {
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    line-height: ${rem(20)};
  }
`;

export default class StarRating extends Component {
  trackStarRatingClick(title, location, e) {
    e.preventDefault();
    const event = {
      title,
      location,
    };

    metrics.track("CTA Clicked", event);
  }

  handleClick(event) {
    if (!this.props.productHero) return;
    this.trackStarRatingClick("Star Rating", "hero", event);
    const reviewsSectionPosition =
      $("#reviews").offset().top - (atMost.tablet() ? 52 : 128);
    $(window).scrollTop(reviewsSectionPosition);
  }

  render() {
    const { score, productHero, readReviews, id = "star-rating" } = this.props;

    return (
      <StarRatingWrapper
        id={id}
        onClick={this.handleClick.bind(this)}
        productHero={productHero}
      >
        <StarCount id={id}>
          <ReviewStars score={score} />
        </StarCount>
        <ReviewCount id={id} className={readReviews ? "-read" : ""}>
          {readReviews
            ? intl.t("product.read-reviews", "Read Reviews")
            : intl.t(
                "product.star-rating.read-score",
                "{score} out of 5.0 stars",
                {
                  score: score.toFixed(1),
                },
              )}
        </ReviewCount>
      </StarRatingWrapper>
    );
  }
}
