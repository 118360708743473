import { Component } from "react";
import styled from "styled-components";
import { Font, Color, rem, media } from "../../utils/style";
import ReviewStars from "./ReviewStars";
import Container from "../Container";
import Row from "../Row";
import ReviewPolicyModal from "../reviews/ReviewPolicyModal";
import Text from "../Text";

const Wrapper = styled.div.attrs({
  className:
    "col-12 col-sm-10 col-md-8 offset-sm-1 offset-md-2 text-center d-flex align-items-center justify-content-center flex-column",
})`
  margin-bottom: 56px;

  ${media.tablet`
    margin-bottom: 40px;
  `};
`;

const Rating = styled.h2.attrs({
  className: "text-center m-0 mb-3 mb-md-4",
})`
  font-size: ${rem(30)};
  line-height: ${rem(36)};
  letter-spacing: -0.4px;
  font-weight: 500;
  color: ${Color.ritualBlue};

  ${media.tablet`
    font-size: ${rem(22)};
    line-height: ${rem(32)};
    letter-spacing: -0.2px;
  `};
`;

const Paragraph = styled.p.attrs({
  className: "text-center m-0",
})`
  ${Font.dutch}
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  max-width: 770px;
`;

const StarWrapper = styled.div.attrs({
  className: "d-flex m-0 mb-3",
})`
  .ReviewStars--star {
    width: 40px;
    height: 42px;

    ${media.tablet`
      width: 32px;
      height: 34px;
    `};
  }
`;

const LearnMoreButton = styled.a.attrs({
  className: "-underline",
})`
  border-width: 1px !important;
  white-space: nowrap;
`;

export default class AggregatedStarRating extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  openModal(e) {
    e.preventDefault();
    this.setState({ modalIsOpen: true });
  }
  closeModal() {
    this.setState({ modalIsOpen: false });
  }
  render() {
    let { score } = this.props;
    let roundedScore = Math.round(score * 10) / 10;

    return (
      <Container>
        <Row>
          <Wrapper>
            <StarWrapper>
              <ReviewStars score={score} />
            </StarWrapper>
            <Rating>
              <Text
                id="reviews.agg-star.rating"
                defaultMessage="{score} out of 5.0 stars"
                values={{ score: roundedScore.toFixed(1) }}
              />
            </Rating>
            <Paragraph>
              <Text
                id="reviews.agg-star.p"
                defaultMessage="We're all about transparency. Read reviews by real people who take Ritual."
              />{" "}
              <LearnMoreButton onClick={this.openModal}>
                <Text id="general.learn-more" defaultMessage="Learn More" />
              </LearnMoreButton>
              .
            </Paragraph>
          </Wrapper>
        </Row>

        <ReviewPolicyModal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
        />
      </Container>
    );
  }
}
