// Utils
import metrics from "../metrics";
import { getPropertiesForPlanId } from "./helpers";

export function trackProductViewed(planId, additionalProperties) {
  const productProperties = getPropertiesForPlanId(planId);

  let properties = {
    products: [productProperties],
  };

  properties = { ...properties, ...productProperties, ...additionalProperties };

  metrics.track("Product Viewed", properties, {
    addTraitsToContext: true,
    addEmail: true,
    addFacebookCookies: true,
    addTikTokEventId: true,
  });
}
