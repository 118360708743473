// GraphQL
import { useStaticQuery, graphql } from "gatsby";

const getHowToUseData = () => {
  const data = useStaticQuery(graphql`
    query HowToUseQuery {
      proteinImage: contentfulAsset(
        contentful_id: { eq: "SXPmVL3NthVBxRcD2x5et" }
      ) {
        file {
          url
        }
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 970
          quality: 90
        )
        description
      }
    }
  `);

  return {
    "essential.protein": {
      content: [
        {
          id: "product.protein.how-to-use.steps.first",
          defaultMessage: "Start with 8-12 oz of any cold liquid",
        },
        {
          id: "product.protein.how-to-use.steps.second",
          defaultMessage: "Add 1 serving of powder",
        },
        {
          id: "product.protein.how-to-use.steps.third",
          defaultMessage: "Shake it like you mean it",
        },
      ],
      instructions: {
        id: "product.protein.how-to-use.instructions",
        defaultMessage:
          "For best results, use a shaker bottle or blender instead of stirring—Essential Protein requires agitation to break down.",
      },
      image: data?.proteinImage,
    },
  };
};

export { getHowToUseData };
