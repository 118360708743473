import styled, { css } from "styled-components";
// Data
import quantityPickerDefaultOptions from "../../data/product/quantityPickerDefaultOptions";
import { getBundleCartLimit } from "../../utils/bundle";
// Utils
import { responsive } from "../../utils/style";
// Components
import MagicSelect from "../MagicSelect";

const quantityPickerStyleStyle = css`
  .custom-select-button {
    margin-top: 0;
  }

  .dropdown-wrapper {
    svg {
      right: 31px;
      top: 21px;
    }
  }

  .custom-select-trigger,
  .styled-select {
    height: 50px;
    padding: 0 30px;

    svg {
      right: 30px;
      top: 20px;
    }
  }

  .custom-select-option {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const QuantityPickerSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${responsive.sm`
    justify-content: flex-start;
    width: 270px;
  `};

  a {
    width: 100%;

    ${responsive.sm`
      width: auto;
    `}

    ${responsive.md`
      width: 100%;
    `}
  }

  & > :first-child {
    margin-bottom: 16px;
  }

  // Fixes wiggle when switching between mouse & keyboard functionality
  div[class*="DropdownWrapper"] {
    margin-top: 0 !important;
  }
`;

const QuantityPicker = (props) => {
  const {
    quantity,
    cartQuantity,
    quantityPickerOptions,
    updateQuantity,
    children,
  } = props;

  const allOptions = quantityPickerOptions || quantityPickerDefaultOptions();
  const availableNumberOfProducts = getBundleCartLimit() - cartQuantity || 1;
  const availableOptions = allOptions.slice(0, availableNumberOfProducts);

  function handleSelectionMade(value) {
    updateQuantity(parseInt(value));
  }

  return (
    <QuantityPickerSection data-testid="quantity-picker">
      <MagicSelect
        options={availableOptions}
        contentStyle={quantityPickerStyleStyle}
        rounded={true}
        currentOption={{
          title:
            availableOptions[quantity - 1]?.title || availableOptions[0].title,
          value: quantity,
        }}
        onSelectionMade={handleSelectionMade}
      />
      {children}
    </QuantityPickerSection>
  );
};

export default QuantityPicker;
