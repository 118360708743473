import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { createRef, Component } from "react";
import { createPortal } from "react-dom";
import Draggable from "react-draggable";
import styled from "styled-components";
import keyCodes from "../utils/keyCode";
import { lockScroll } from "../utils/lockScroll";
import { Color, Opacity, responsive } from "../utils/style";
import { Icons } from "../utils/react-svg";
import ScrollableOverflow from "./ScrollableOverflow";

const Transition = 0.5;

const ZoomOverlay = styled.div`
  position: fixed;

  top: ${(p) => p.initialTop}px;
  left: ${(p) => p.initialLeft}px;
  width: ${(p) => p.initialWidth}px;
  height: ${(p) => p.initialHeight}px;

  background-color: rgba(0, 0, 0, ${Opacity.light});
  z-index: 9999;

  &.entering {
    transition: top ${Transition}s, left ${Transition}s, width ${Transition}s,
      height ${Transition}s;
    top: ${(p) => p.transitionTop}px;
    left: ${(p) => p.transitionLeft}px;
    width: ${(p) => p.imageWidth}px;
    height: ${(p) => p.imageHeight}px;
  }

  &.post-enter {
    transition: none;
    top: 0;
    left: 0;
    width: ${(p) => p.windowWidth}px;
    height: ${(p) => p.windowHeight}px;
  }

  &.pre-exit {
    top: ${(p) => p.transitionTop}px;
    left: ${(p) => p.transitionLeft}px;
    width: ${(p) => p.imageWidth}px;
    height: ${(p) => p.imageHeight}px;
  }

  &.exiting {
    transition: top ${Transition}s, left ${Transition}s, width ${Transition}s,
      height ${Transition}s;
    top: ${(p) => p.initialTop}px;
    left: ${(p) => p.initialLeft}px;
    width: ${(p) => p.initialWidth}px;
    height: ${(p) => p.initialHeight}px;
  }
`;

const CarouselWrapper = styled.div`
  visibility: ${(p) => (p.visible ? "visible" : "hidden")};
  width: 100%;
  height: ${(p) => (p.visible ? "100%" : 0)};

  overflow-y: scroll;
  overflow-x: hidden;
`;

const PanningContainer = styled.div`
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
`;

const TransitionImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  transition: width ${Transition}s, height ${Transition}s;
`;

const ImageWrapper = styled.div`
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
`;

const Button = styled.button`
  appearance: none;
  background: none;
  border: none;
  padding: 0;

  border-radius: 50%;
  background-color: ${Color.white};
  width: 48px;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    svg {
      opacity ${Opacity.light};
    }
  }

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 20px;
  right: 20px;

  svg {
    width: 16px;
    height: 16px;
  }

  ${responsive.sm`
    top: 24px;
    right: 24px;
  `}

  ${responsive.md`
    top: 40px;
    right: 40px;
  `}
`;

const PaginationButton = styled(Button)`
  width: 56px;
  height: 56px;

  svg {
    width: 26px;
    height: 16px;

    g {
      stroke-width: 1.75;
    }
  }

  &:hover {
    svg {
      opacity: ${Opacity.light};
    }
  }
`;

const LeftButton = styled(PaginationButton)`
  position: absolute;
  bottom: 24px;
  left: calc(50% - 72px);

  ${responsive.sm`
    bottom: calc(50% - 56px);
    left: 24px;
  `}

  ${responsive.md`
    left: 40px;
  `}
`;

const RightButton = styled(PaginationButton)`
  position: absolute;
  bottom: 24px;
  right: calc(50% - 72px);

  ${responsive.sm`
    bottom: calc(50% - 56px);
    right: 24px;
  `}

  ${responsive.md`
    right: 40px;
  `}
`;

export class ZoomComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      windowHeight: 0,
      windowWidth: 0,
      index: props.index || 0,
      entering: false,
      postEnter: false,
      preExit: false,
      exiting: false,
    };

    this.handleEscDown = this.handleEscDown.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.scrollableRef = createRef();
    this.panningRef = createRef();

    this.zoomImageResolvers = [];
    this.zoomImagePromises = [];

    props.images.forEach(() => {
      this.zoomImagePromises.push(
        new Promise((resolve) => {
          this.zoomImageResolvers.push(resolve);
        }),
      );
    });
  }

  async componentDidMount() {
    lockScroll(true, this.panningRef.current);

    window.addEventListener("keydown", this.handleEscDown);
    window.addEventListener("resize", this.handleResize);

    await this.handleResize();

    this.setState({
      entering: true,
    });

    await this._delay(Transition);
    await Promise.all(this.zoomImagePromises);

    this._transitionToPostEnter();
  }

  componentWillUnmount() {
    lockScroll(false, this.panningRef.current);
    window.removeEventListener("keydown", this.handleEscDown);
    window.removeEventListener("resize", this.handleResize);
  }

  handleEscDown(e) {
    if (e.keyCode === keyCodes.ESC) {
      this.handleClose();
    }
  }

  handleResize() {
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    return new Promise((resolve) => {
      this.setState(
        {
          windowHeight,
          windowWidth,
        },
        resolve,
      );
    });
  }

  async handleClose() {
    this.setState(
      {
        preExit: true,
      },
      () => {
        this.setState({
          exiting: true,
        });
      },
    );

    await this._delay(Transition);
    this.props.onClose();
  }

  _transitionToPostEnter() {
    this.setState(
      {
        postEnter: true,
      },
      () => {
        this.centerImage();
        this.scrollToIndex(this.state.index, "center", false);
      },
    );
  }

  _delay(seconds) {
    return new Promise((resolve) => {
      setTimeout(resolve, seconds * 1000);
    });
  }

  previousImage() {
    this.updateIndex(this.state.index - 1);
  }

  nextImage() {
    this.updateIndex(this.state.index + 1);
  }

  updateIndex(index) {
    index = Math.max(0, index);
    index = Math.min(index, this.props.images.length - 1);

    if (index === this.state.index) return;

    this.scrollToIndex(index);
  }

  scrollToIndex(index, position = "left", smooth = true) {
    if (this.props.onIndexChange) {
      this.props.onIndexChange(index);
    }

    this.setState({
      index,
    });
    this.scrollableRef.current.scrollToIndex(index, position, smooth);
  }

  isSmall() {
    return this.state.windowWidth < 960;
  }

  getCurrentImage() {
    return this.props.images[this.state.index];
  }

  getAspectRatio() {
    const currentImage = this.getCurrentImage();
    return (
      currentImage.gatsbyImageData.width / currentImage.gatsbyImageData.height
    );
  }

  getImageHeight() {
    if (this.isSmall()) {
      return this.state.windowHeight;
    }
    return this.state.windowWidth / this.getAspectRatio();
  }

  getImageWidth() {
    if (this.isSmall()) {
      return this.state.windowHeight * this.getAspectRatio();
    }
    return this.state.windowWidth;
  }

  centerImage() {
    const scrollOffset = this.getInitialScrollOffset();

    // This should only be false for testing.
    if (!this.panningRef.current.scrollTo) return;

    this.panningRef.current.scrollTo({
      top: scrollOffset.top,
    });
  }

  getInitialScrollOffset() {
    if (this.isSmall()) {
      return {
        top: 0,
        left: Math.max((this.getImageWidth() - this.state.windowWidth) / 2, 0),
      };
    }

    return {
      top: Math.max((this.getImageHeight() - this.state.windowHeight) / 2, 0),
      left: 0,
    };
  }

  getDraggableSettings() {
    const scrollOffset = this.getInitialScrollOffset();
    const isSmall = this.isSmall();

    const bounds = {
      left: isSmall ? scrollOffset.left * -1 : 0,
      right: isSmall ? scrollOffset.left : 0,
      top: 0,
      bottom: 0,
    };

    const positionOffset = {
      x: isSmall ? scrollOffset.left * -1 : 0,
      y: 0,
    };

    return {
      bounds,
      positionOffset,
    };
  }

  isTransitioning() {
    const { postEnter, preExit } = this.state;
    return !postEnter || preExit;
  }

  renderImage(image, callback) {
    return (
      <GatsbyImage
        image={getImage(image)}
        loading="eager"
        fadeIn={false}
        alt={image.description}
        imgStyle={{ objectFit: "contain" }}
        style={{
          userSelect: "none",
          userDrag: "none",
          pointerEvents: "none",
          touchCallout: "none",
          width: "100%",
          height: "100%",
        }}
        onLoad={callback}
      />
    );
  }

  renderImages() {
    const image = this.getCurrentImage();
    const isTransitioning = this.isTransitioning();
    const draggableSettings = this.getDraggableSettings();

    // If no higher quality zoom images are specified, simply use the images.
    const zoomImages = this.props.zoomImages || this.props.images;

    return (
      <>
        {/** Zoom Carousel */}
        <CarouselWrapper ref={this.panningRef} visible={!isTransitioning}>
          <Draggable {...draggableSettings}>
            <PanningContainer
              width={this.getImageWidth()}
              height={this.getImageHeight()}
            >
              <ScrollableOverflow ref={this.scrollableRef}>
                {zoomImages.map((image, index) => {
                  return (
                    <ImageWrapper
                      key={index}
                      width={this.getImageWidth()}
                      height={this.getImageHeight()}
                    >
                      {this.renderImage(image, () => {
                        this.zoomImageResolvers[index]();
                      })}
                    </ImageWrapper>
                  );
                })}
              </ScrollableOverflow>
            </PanningContainer>
          </Draggable>
        </CarouselWrapper>

        {/** Transition Image */}
        {isTransitioning && (
          <TransitionImageWrapper>
            {this.renderImage(image)}
          </TransitionImageWrapper>
        )}
      </>
    );
  }

  render() {
    const { index } = this.state;
    const rect = this.props.domNodes[index].getBoundingClientRect();
    const scrollOffset = this.getInitialScrollOffset();
    const isTransitioning = this.isTransitioning();
    const totalCount = this.props.images.length;

    return (
      <ZoomOverlay
        className={`
          ${this.state.entering ? "entering" : ""}
          ${this.state.postEnter ? "post-enter" : ""}
          ${this.state.preExit ? "pre-exit" : ""}
          ${this.state.exiting ? "exiting" : ""}
        `}
        initialTop={rect.top}
        initialLeft={rect.left}
        initialWidth={rect.width}
        initialHeight={rect.height}
        transitionTop={scrollOffset.top * -1}
        transitionLeft={scrollOffset.left * -1}
        imageWidth={this.getImageWidth()}
        imageHeight={this.getImageHeight()}
        windowWidth={this.state.windowWidth}
        windowHeight={this.state.windowHeight}
      >
        {this.renderImages()}

        {!isTransitioning && (
          <>
            <CloseButton onClick={this.handleClose}>
              <Icons.Close />
            </CloseButton>
            {totalCount > 1 && (
              <>
                <LeftButton
                  disabled={index === 0}
                  onClick={this.previousImage.bind(this)}
                >
                  <Icons.ArrowRoundedLeft />
                </LeftButton>
                <RightButton
                  disabled={index === totalCount - 1}
                  onClick={this.nextImage.bind(this)}
                >
                  <Icons.ArrowRoundedRight />
                </RightButton>
              </>
            )}
          </>
        )}
      </ZoomOverlay>
    );
  }
}

export default class MagicZoom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      zoomRoot: null,
    };
  }

  componentDidMount() {
    const zoomRoot = document.getElementById("zoom-root");

    if (zoomRoot) {
      this.setState({ zoomRoot });
    }
  }

  render() {
    const { zoomRoot } = this.state;
    if (zoomRoot) {
      return createPortal(<ZoomComponent {...this.props} />, zoomRoot);
    }
    return <></>;
  }
}
