import useProductOfferUpsells from "../../../hooks/launchdarkly/useProductOfferUpsells";
import { useSelector } from "react-redux";
import { productOfferForSlug } from "../../../store/product-offer/selectors";
import ProductOfferUpsellTile from "../ProductOfferUpsellTile";
import styled from "styled-components";
import intl from "../../../services/intl";
import { Color, responsive } from "../../../utils/style";
import useVariation from "../../../hooks/useVariation";
import { useState } from "react";
import { MagicSVGImage } from "../../MagicSVGImage";
import { IconCaretRight } from "../../../utils/svg";
import useBreakpoint from "../../../hooks/useBreakpoint";

const ProductOfferUpsellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 370px;
  margin-top: var(--spacing-2);

  ${responsive.lg`
    margin-top: 40px;
   `}
`;

const BundleAndSave = styled.span`
  margin-bottom: var(--spacing-1);
  margin-top: var(--spacing-2);
  font-weight: 500;

  ${responsive.lg`
    margin-top: 40px;
   `}
`;

const UpsellListItem = styled.div`
  margin-bottom: var(--spacing-0_75);

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const AccordionButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  align-self: center;
  color: ${Color.ritualBlue};
  margin: var(--spacing-0_75) 0 0;
  display: flex;

  &.upsell-list-closed {
    img {
      transform: rotate(90deg);
    }
  }

  &.upsell-list-open {
    img {
      transform: rotate(270deg);
    }
  }
`;

const IconWrapper = styled.span`
  margin-left: var(--spacing-1);
  display: flex;
  height: 100%;
  align-items: center;
`;

type Props = {
  productSku: string;
};

type AccordionButtonProps = {
  isOpen: boolean;
  onClick: () => void;
};

const AccordionButtonWrapper = ({ isOpen, onClick }: AccordionButtonProps) => {
  let buttonText = intl.t("upsell-tile.see-more-bundles", "See More Bundles");

  if (isOpen) {
    buttonText = intl.t("upsell-tile.see-less", "See Less");
  }

  return (
    <AccordionButton
      id={"upsell-list-btn"}
      aria-expanded={isOpen}
      aria-controls={"upsell-list-items"}
      className={`typography-label2 ${
        isOpen ? "upsell-list-open" : "upsell-list-closed"
      }`}
      type={"button"}
      onClick={onClick}
    >
      <span>{buttonText}</span>
      <IconWrapper>
        <MagicSVGImage
          alt={"caret-icon-up"}
          width={16}
          height={16}
          src={IconCaretRight}
          aria-hidden={true}
        />
      </IconWrapper>
    </AccordionButton>
  );
};

const ProductOfferUpsell = ({ productSku }: Props) => {
  const isSmallScreen = useBreakpoint() === "xs";

  const [accordionOpen, setAccordionOpen] = useState(false);
  const productOfferUpsells = useProductOfferUpsells(
    "product-offer-pdp-upsells",
    productSku,
  );
  const numberOfProductOfferTilesToShow = useVariation(
    "product-offer-pdp-upsell-tile-count",
    1,
  );

  const productOffers = useSelector((state) =>
    productOfferUpsells
      .map((productOfferUpsell) =>
        productOfferForSlug(state, productOfferUpsell?.slug),
      )
      .filter(Boolean),
  );

  if (
    !productOfferUpsells.length ||
    !productOffers.length ||
    productOfferUpsells.length !== productOffers.length
  ) {
    return null;
  }

  const productOffersToShow = productOffers.slice(
    0,
    numberOfProductOfferTilesToShow,
  );
  const shouldShowAccordionButton =
    !isSmallScreen && productOffersToShow.length > 1;

  return (
    <ProductOfferUpsellWrapper>
      <BundleAndSave className={"typography-lead2"}>
        {intl.t("upsell-tile.bundle-and-save", "Bundle and Save")}
      </BundleAndSave>

      <div
        id={"upsell-list-items"}
        role={"section"}
        aria-labelledby="upsell-list-btn"
      >
        {productOffersToShow.map((productOffer, index) => {
          if (!isSmallScreen && !accordionOpen && index > 0) {
            return null;
          }

          return (
            <UpsellListItem>
              <ProductOfferUpsellTile
                labelText={productOfferUpsells[index].label}
                productOffer={productOffer}
              />
            </UpsellListItem>
          );
        })}
      </div>

      {shouldShowAccordionButton && (
        <AccordionButtonWrapper
          onClick={() => {
            setAccordionOpen(!accordionOpen);
          }}
          isOpen={accordionOpen}
        />
      )}
    </ProductOfferUpsellWrapper>
  );
};

export default ProductOfferUpsell;
