import NameMap from "./nameMap";

type ProductIngredientWithDosages = {
  productDosages: { childMarkdownRemark: { rawMarkdownBody: string } };
};
type ProductNodeWithName = { name: { name: string } };
type IngredientDosage = {
  productName: string;
  dosage: string;
};

function isProductIngredientWithDosages(
  productIngredient: any,
): productIngredient is ProductIngredientWithDosages {
  return (
    productIngredient?.productDosages?.childMarkdownRemark?.rawMarkdownBody !==
    undefined
  );
}

function isProductNodeWithName(
  productNode: any,
): productNode is ProductNodeWithName {
  return productNode?.name?.name !== undefined;
}

function isValidDosages(dosages: any): dosages is IngredientDosage[] {
  return (
    Array.isArray(dosages) &&
    dosages.every((dosage) => dosage.productName && dosage.dosage)
  );
}

function parseDosages(productIngredient: ProductIngredientWithDosages): any[] {
  try {
    return JSON.parse(
      productIngredient.productDosages.childMarkdownRemark.rawMarkdownBody,
    );
  } catch (error) {
    throw new Error("Unable to parse ingredient dosages", { cause: error });
  }
}

export default function findDosageByProduct(
  productIngredient: any,
  product: any,
): string | undefined {
  if (!isProductIngredientWithDosages(productIngredient)) return undefined;
  if (!isProductNodeWithName(product)) return undefined;

  const dosages = parseDosages(productIngredient);
  if (!isValidDosages(dosages)) {
    throw new Error("Ingredient dosages are not valid");
  }

  const productName = NameMap(product.name).plain;

  const dosage = dosages.find(
    (item) => item.productName === productName,
  )?.dosage;

  return dosage;
}
