import styled from "styled-components";

// Constants
import { ActionType } from "../../../constants/product";

// Components
import Highlighter from "../../global/Highlighter";
import Text from "../../Text";

// Hooks
import useDiscount from "../../../hooks/discount/useDiscount";

// Utils
import { rem, responsive } from "../../../utils/style";
import { getPromotionDetails } from "../../../utils/promotion";

const EyebrowWrapper = styled.div`
  font-weight: 500;
  text-transform: uppercase;

  font-size: ${rem(16)};
  line-height: ${rem(26)};
  letter-spacing: 1px;
  margin-bottom: 16px;

  ${responsive.md`
    font-size: ${rem(18)};
    line-height: ${rem(28)};
    letter-spacing: 1.2px;
    margin-bottom: 24px;
  `}
`;

export default function ActionEyebrow({ product, actionType }) {
  if (actionType === ActionType.AddToCart) {
    return null;
  }

  const isSwitch = actionType === ActionType.Switch;

  const promotion = useDiscount(actionType, 1, product);

  const promotionDetails = getPromotionDetails(promotion);

  function getText() {
    if (isSwitch && promotion) {
      return (
        <Text
          id="product.incentive.eyebrow.switch-and-save"
          defaultMessage="Switch & save {value} with"
          values={{ value: promotionDetails.formattedValue }}
        />
      );
    }

    if (isSwitch) {
      return (
        <Text
          id="product.incentive.eyebrow.switch"
          defaultMessage="Switch To"
        />
      );
    }

    if (promotion) {
      return (
        <Text
          id="product.incentive.eyebrow.add-and-save"
          defaultMessage="Add & save {value} with"
          values={{ value: promotionDetails.formattedValue }}
        />
      );
    }

    return (
      <Text
        id="product.incentive.eyebrow.add"
        defaultMessage="Add to Your Ritual"
      />
    );
  }

  return (
    <EyebrowWrapper>
      <Highlighter hover={false} percent={25}>
        <span>{getText()}</span>
      </Highlighter>
    </EyebrowWrapper>
  );
}
