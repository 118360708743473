import { Component } from "react";
import styled from "styled-components";
import Container from "../Container";
import Row from "../Row";
import Text from "../Text";
import RitualButton from "../global/RitualButton";
import { responsive, Color, rem, Font } from "../../utils/style";
import { Icons } from "../../utils/react-svg";

const GuideColumn = styled.section.attrs({
  className: "col-12 col-sm-8 offset-sm-2",
  "aria-labelledby": "guide-title",
})`
  text-align: center;
  margin-top: 48px;
  margin-bottom: 80px;

  ${responsive.md`
    margin-top: 80px;
    margin-bottom: 120px;
  `}
`;

const GuideTitle = styled.h2`
  ${Font.circular}
  font-size: ${rem(22)};
  line-height: ${rem(32)};
  color: ${Color.ritualBlue};
  font-weight: 500;
  letter-spacing: -0.2px;
  text-align: center !important;
  margin: 0 0 24px;
  padding-top: 28px;

  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: calc(50% - 16px);
    width: 32px;
    height: 4px;
    background-color: ${Color.ritualBlue};
  }
`;

const GuideButton = styled(RitualButton)`
  svg {
    width: 14px;
    height: 12px;
    margin-left: 8px;
  }
`;

export default class DownloadGuide extends Component {
  render() {
    let { guideText, doctorGuide, trackCTAClick } = this.props;

    return (
      <Container className="ajs_download-guide">
        <Row className="ajs_download-guide_row">
          <GuideColumn className="ajs_download-guide_row_column">
            <GuideTitle id="guide-title">{guideText}</GuideTitle>
            <GuideButton
              onClick={trackCTAClick.bind(
                null,
                "Download the Guide",
                "Discussion Guide",
                doctorGuide.file.url,
              )}
              href={doctorGuide.file.url}
              className="bordered product-guide__btn__download"
            >
              <Text
                id="product.download-guide"
                defaultMessage="Download the Guide"
              />{" "}
              <Icons.Download />
            </GuideButton>
          </GuideColumn>
        </Row>
      </Container>
    );
  }
}
