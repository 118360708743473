import { graphql, useStaticQuery } from "gatsby";
import intlService from "../../services/intl";
import {
  RenderRichTextData,
  renderRichText,
} from "gatsby-source-contentful/rich-text";

type ProductButtonTooltipPromotionNode = {
  promotionSlug: string;
  content: RenderRichTextData<any>;
};

const parseNode = (node: ProductButtonTooltipPromotionNode) => {
  const content = renderRichText(node.content);

  return { node, content };
};

const useProductButtonTooltipPromotion = (promotionSlug?: string) => {
  const { allContentfulProductButtonTooltipPromotion } = useStaticQuery(graphql`
    query ProductButtonTooltipPromotion {
      allContentfulProductButtonTooltipPromotion {
        nodes {
          node_locale
          promotionSlug
          content {
            raw
          }
        }
      }
    }
  `);
  const localNodes = intlService.cf(
    allContentfulProductButtonTooltipPromotion.nodes,
  ) as ProductButtonTooltipPromotionNode[];
  const node = localNodes.find((n) => n.promotionSlug === promotionSlug);

  return node ? parseNode(node) : null;
};

export default useProductButtonTooltipPromotion;
