import { useState } from "react";
import styled from "styled-components";

// Services
import intl from "../../../services/intl";

// Components
import Text from "../../Text";

// Redux
import { useSelector } from "react-redux";
import promotionSelectors from "../../../store/promotion/selectors";

// Hooks
import useVariation from "../../../hooks/useVariation";

// Utils
import { Color, rem, responsive } from "../../../utils/style";
import { buildUrl } from "../../../utils/imgix";
import { productPromotionPrice } from "../../../utils/productPromotion";
import { trackProductPromotionViewed } from "../../../utils/tracking/promotion";

const SpacingWrapper = styled.div``;

const ShakerPromoCard = styled.div`
  display: flex;
  width: 100%;
  background-color: ${Color.ritualGrey};
  padding: 16px;
  margin-bottom: 16px;

  ${responsive.sm`
    padding: 16px 24px;
    margin-bottom: 0;
  `}

  ${responsive.md`
    margin-bottom: 16px;
  `}

  div.image-wrapper {
    position: relative;
    flex: 1 0 auto;
    height: 64px;
    width: 64px;
    background-color: ${Color.ritualBlue};
    margin-right: 16px;

    ${responsive.sm`
      height: 72px;
      width: 72px;
    `}
  }

  div {
    &:last-of-type {
      display: flex;
      flex-direction: column;
    }
  }

  span {
    &:first-of-type {
      background: ${Color.ritualYellow};
      padding: 7px 16px;
      border-radius: 6px;
      width: fit-content;
      margin-bottom: 8px;
      color: ${Color.ritualBlue};
      font-size: ${rem(10)};
      letter-spacing: 0.67px;
      line-height: ${rem(17)};
      text-align: center;
      text-transform: uppercase;
    }

    &:last-of-type {
      color: ${Color.lessFadedBlue};
      font-size: ${rem(12)};
      font-weight: 300;
      letter-spacing: 0px;
      line-height: ${rem(18)};
    }
  }
`;

const ShakerPromo = (props) => {
  const { promoProduct, productData, className } = props;
  const shakerPromoEnabled = !!useSelector(
    promotionSelectors.activeProductPromotion,
  );
  const isProteinProduct = productData.subCategory === "Protein";
  const showShakerPromo = shakerPromoEnabled && isProteinProduct;
  const [shakerPromoTracked, setShakerPromoTracked] = useState(false);

  if (!showShakerPromo) {
    return null;
  }

  if (!shakerPromoTracked) {
    trackProductPromotionViewed("PROSHAKER");
    setShakerPromoTracked(true);
  }
  return (
    <SpacingWrapper className={className}>
      <ShakerPromoCard>
        <div className="image-wrapper">
          {promoProduct && (
            <img
              loading="eager"
              alt={promoProduct.name}
              src={buildUrl(promoProduct.imagePath)}
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                zIndex: "0",
                userSelect: "none",
                userDrag: "none",
                pointerEvents: "none",
                touchCallout: "none",
              }}
            />
          )}
        </div>
        <div>
          <span>
            <Text
              id="product.promotions.PROSHAKER.free-shaker"
              defaultMessage="Free Shaker"
            />
          </span>
          <span>
            <Text
              id="product.promotions.PROSHAKER.description-with-value-alternative"
              defaultMessage="Get a free Ritual Shaker ($30 value) with your first Essential Protein purchase. Limited quantities available."
              values={{
                price: intl.formatCurrency(productPromotionPrice("PROSHAKER"), {
                  round: true,
                }),
              }}
            />
          </span>
        </div>
      </ShakerPromoCard>
    </SpacingWrapper>
  );
};

export default ShakerPromo;
