import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled, { css } from "styled-components";
import { Icons } from "../../../utils/react-svg";
import {
  Font,
  Opacity,
  media,
  rem,
  responsive,
  rgba,
} from "../../../utils/style";
import { Color, Typography } from "../../../utils/styleDesignSystem";
import MagicModal from "../../MagicModal";
import Text from "../../Text";

const contentStyle = css`
  padding: 48px 24px 40px;

  ${responsive.sm`
    padding: 40px;
    max-width: 460px;
    max-height: 614px;
  `};

  ${responsive.md`
    padding: ${(props) => (props.headerImage ? "56px 100px" : "56px 80px")};
    max-width: 774px;
  `};
`;

const singleIngredientStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;
  gap: 32px;

  ${responsive.sm`
    padding: 32px;
    max-width: 460px;
  `};

  ${responsive.md`
    display: inline-flex;
    padding: 64px 100px;
    max-width: 770px;
    max-height: 614px;
  `};
`;

const ModalContentWrapper = styled.div`
  border-top: 2px solid ${Color.ritualBlue};

  hr {
    border-top-width: 1px;
    align-self: stretch;
    background: ${Color.indigoBlue20};
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  ${responsive.sm`
    margin-bottom: 32px;
  `}

  &.single-ingredient {
    margin-bottom: unset;
    text-wrap: balance;

    ${responsive.sm`
      text-wrap: pretty;
    `}
  }
`;

const Header = styled.h3`
  letter-spacing: -0.2px;
  font-size: ${rem(22)};
  line-height: ${rem(32)};
  margin: 0;

  &.protein-study {
    max-width: 340px;
    margin-bottom: 16px;
  }

  ${responsive.md`
    letter-spacing: -0.4px;
    font-size: ${rem(30)};
    line-height: ${rem(36)};
  `};

  i {
    ${Font.dutch};
  }
`;

const Subheader = styled.p`
  ${Font.dutch};
  margin: 0;
  letter-spacing: 0;
  font-size: ${rem(16)};
  line-height: ${rem(26)};

  ${responsive.md`
    font-size: ${rem(18)};
    line-height: ${rem(28)};
  `};

  ${media.mobile`
    margin-bottom: 24px;
  `}
`;

const Content = styled.div`
  p {
    ${Font.dutch};
    font-size: ${rem(16)};
    line-height: ${rem(26)};
  }

  a,
  a:hover {
    text-decoration: underline;
  }

  .light {
    color: rgba(20, 43, 111, ${Opacity.light});
    font-weight: 300;
  }
`;

const StudyResultsContentContainer = styled.div`
  margin: 32px 0 0;
`;

const StudySummaryContentContainer = styled.div`
  margin: 24px 0 0;
`;

const StudyDescription = styled.div`
  ${Typography.body1}

  p {
    margin: 32px 0 48px;
  }

  p.withImage {
    margin-bottom: 12px;
  }

  &.single-ingredient {
    margin-bottom: unset;

    p {
      font-size: ${rem(16)};
      font-weight: 400;
      line-height: 26px;
    }
  }

  ${responsive.md`
    p.withImage {
      font-size: ${rem(18)};
    }
  `};
`;

const StudyResults = styled.div`
  h2 {
    font-size: ${rem(14)};
    line-height: ${rem(22)};
    text-transform: uppercase;
    margin: 0 0 8px 0;
    color: ${rgba(Color.indigoBlue, Opacity.light)};

    ${responsive.md`
      margin-bottom: 16px;
    `};
  }

  ul {
    list-style: none;
    margin: 0 0 32px 0;
    padding: 0;

    li {
      margin: 0 0 24px 0;
      display: flex;

      &:last-child {
        margin-bottom: 0;
      }

      svg {
        margin-right: 10px;
        width: 15px;
        flex: 0 0 auto;
      }

      p {
        ${Font.circular};
        font-size: ${rem(16)};
        line-height: ${rem(22)};
        margin: 0;
      }
    }
  }
`;

const StudySummary = styled.div`
  h3 {
    font-size: 1rem;
    line-height: 1.625rem;
    margin: 0 0 8px;
  }

  p {
    margin-bottom: 32px;

    ${responsive.md`
      margin-bottom: 48px;
    `};

    &.protein {
      font-size: ${rem(18)};
      line-height: ${rem(28)};

      ${responsive.md`
        margin-bottom: 0;
      `};
    }
  }
`;

const ImageWrapper = styled.div`
  flex-shrink: 0;

  width: 84px;
  height: 96px;
  margin-left: 24px;

  ${responsive.md`
    width: 108px;
    height: 124px;
    margin-left: 40px;
  `}
`;

const IconWrapper = styled.div`
  margin-right: 8px;
`;

const DesktopImage = styled(GatsbyImage)`
  display: none !important;

  ${responsive.sm`
    width: 100%;
    height: auto;
    display: block !important;
    margin: 48px 0;
  `};
`;

const MobileImage = styled(GatsbyImage)`
  margin: 32px 0;

  ${responsive.sm`
    display: none !important;
  `};
`;

const Disclaimer = styled.div`
  p {
    ${Typography.caption};
    color: ${Color.indigoBlue40};
    margin-top: 24px;
  }

  a {
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const ProductStudyModal = (props) => {
  const productSubcategory = props.productData.subCategory;
  const productSubcategoryText = productSubcategory
    .replace(" ", "-")
    .toLowerCase();

  const {
    headerImage,
    studyDescriptionImage,
    mobileStudyDescriptionImage,
    content,
  } = props.productStudyModal;
  const {
    header,
    subheader,
    studyDescription,
    studyResults,
    secondaryStudyResults,
    studySummary,
    disclaimer,
  } = content;
  const headerImageData = headerImage && {
    image: getImage(headerImage),
    description: headerImage.description,
  };
  const studyDescriptionImageData = studyDescriptionImage && {
    image: getImage(studyDescriptionImage),
    description: studyDescriptionImage.description,
  };
  const mobileStudyDescriptionImageData = mobileStudyDescriptionImage && {
    image: getImage(mobileStudyDescriptionImage),
    description: mobileStudyDescriptionImage.description,
  };
  const isProteinProduct = productSubcategory === "Protein";
  const hasStudyResults = studyResults?.length > 0;
  const hasSecondaryStudyResults = secondaryStudyResults?.length > 0;

  const useSingleIngredientStyle = productSubcategory === "Single Ingredient";

  return (
    <MagicModal
      headerImage
      contentStyle={
        useSingleIngredientStyle ? singleIngredientStyle : contentStyle
      }
      mobileFullPage={true}
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose} // can handle onClose within template
    >
      <TitleWrapper
        data-test-title-wrapper
        className={useSingleIngredientStyle ? "single-ingredient" : null}
      >
        <div>
          <Header
            subheader
            dangerouslySetInnerHTML={{ __html: header }}
            className={isProteinProduct ? "protein-study" : ""}
          />
          {subheader && (
            <Subheader headerImage className="d-none d-sm-block">
              <Text
                id={`product.study-modal.subheader.${productSubcategoryText}`}
                defaultMessage="{copy}"
                values={{ copy: subheader }}
              />
            </Subheader>
          )}
        </div>
        {headerImageData && (
          <ImageWrapper>
            <GatsbyImage
              image={headerImageData.image}
              alt={headerImageData.description}
              style={{
                userSelect: "none",
                userDrag: "none",
                pointerEvents: "none",
                touchCallout: "none",
                width: "100%",
                height: "100%",
              }}
              imgStyle={{ objectFit: "contain" }}
            />
          </ImageWrapper>
        )}
      </TitleWrapper>

      <ModalContentWrapper>
        <Content>
          {(studyDescription || studyDescriptionImage) && (
            <StudyDescription
              className={useSingleIngredientStyle ? "single-ingredient" : null}
            >
              <p
                className={studyDescriptionImage ? "withImage" : null}
                dangerouslySetInnerHTML={{ __html: studyDescription }}
              />
              {mobileStudyDescriptionImage && (
                <MobileImage
                  image={mobileStudyDescriptionImageData.image}
                  alt={mobileStudyDescriptionImageData.description}
                  imgStyle={{ objectFit: "contain" }}
                  style={{
                    userSelect: "none",
                    userDrag: "none",
                    pointerEvents: "none",
                    touchCallout: "none",
                    width: "100%",
                    height: "100%",
                  }}
                />
              )}
              {studyDescriptionImage && (
                <DesktopImage
                  image={studyDescriptionImageData.image}
                  alt={studyDescriptionImageData.description}
                  imgStyle={{ objectFit: "contain" }}
                  style={{
                    userSelect: "none",
                    userDrag: "none",
                    pointerEvents: "none",
                    touchCallout: "none",
                    width: "100%",
                    height: "100%",
                  }}
                />
              )}
              {hasStudyResults && <hr />}
            </StudyDescription>
          )}

          {hasStudyResults && (
            <StudyResultsContentContainer>
              {studyResults.map((studyResult, i) => {
                return (
                  <StudyResults key={i}>
                    <h2>{studyResult?.title}</h2>
                    <ul>
                      {studyResult.results.map((result, i) => {
                        return (
                          <li key={i}>
                            <IconWrapper>
                              <Icons.ArrowRoundedUp />
                            </IconWrapper>
                            <p dangerouslySetInnerHTML={{ __html: result }}></p>
                          </li>
                        );
                      })}
                    </ul>
                  </StudyResults>
                );
              })}
              {hasSecondaryStudyResults &&
                secondaryStudyResults.map((studyResult, i) => {
                  return (
                    <StudyResults key={i}>
                      <h2>{studyResult.title}</h2>
                      <ul>
                        {studyResult.results.map((result, i) => {
                          return (
                            <li key={i}>
                              <IconWrapper>
                                <Icons.CheckRounded />
                              </IconWrapper>
                              <p>{result}</p>
                            </li>
                          );
                        })}
                      </ul>
                    </StudyResults>
                  );
                })}
            </StudyResultsContentContainer>
          )}
          {(hasStudyResults || hasSecondaryStudyResults) && <hr />}

          {studySummary && (
            <StudySummaryContentContainer>
              {studySummary.map((block, i) => {
                return (
                  <StudySummary key={i}>
                    <h3>{block?.title}</h3>
                    <p
                      className={isProteinProduct ? "protein" : ""}
                      dangerouslySetInnerHTML={{ __html: block.content }}
                    ></p>
                  </StudySummary>
                );
              })}
            </StudySummaryContentContainer>
          )}
        </Content>
        {disclaimer && <hr />}

        {disclaimer && (
          <Disclaimer>
            <p dangerouslySetInnerHTML={{ __html: disclaimer }}></p>
          </Disclaimer>
        )}
      </ModalContentWrapper>
    </MagicModal>
  );
};

export default ProductStudyModal;
