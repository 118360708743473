import { Fragment } from "react";

import styled, { css } from "styled-components";

// Utils
import {
  Color,
  Font,
  Opacity,
  media,
  rem,
  responsive,
} from "../../utils/style";

// Components
import MagicModal from "../MagicModal";
import Text from "../Text";

const contentStyle = css`
  ${responsive.sm`
    max-width: 880px;
    padding: 40px;
  `}
`;

const TableWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  & > div {
    position: relative;
    background-color: ${Color.white};
    box-sizing: border-box !important;
    padding: 20px;
    box-shadow: 0 0 2px rgba(${Color.ritualBlue}, ${Opacity.light});

    ${media.mobile`
      padding: 0;
      width: 100%;
      max-height: 100%;
      overflow-x: scroll;
    `}
  }

  table {
    table-layout: fixed;
    width: 650px;
    text-align: left;
    background-color: ${Color.white};

    ${media.tablet`
      width: 560px;
    `}

    ${media.mobile`
      min-width: calc(100% - 24px);
      margin: 12px;
      margin-bottom: calc(12px + env(safe-area-inset-bottom));
    `}

    thead {
      border: 1px solid ${Color.ritualBlue};

      tr {
        th {
          &:first-child {
            width: 50%;
          }
        }
      }
    }

    thead,
    tbody {
      tr {
        &.outline {
          border: 1px solid ${Color.ritualBlue};
        }

        &.no-top {
          border-top: none;
        }

        &.no-bottom {
          border-bottom: none;
        }
      }

      tr,
      td,
      th {
        ${Font.circular}
        font-size: ${rem(14)};
        line-height: ${rem(18)};
        font-weight: 500;
        text-align: left;
        padding: 4px 6px;
        word-break: break-word;

        ${media.mobile`
          font-size: ${rem(12)};
          line-height: ${rem(16)};
          padding: 2px 4px;
        `}

        &.title {
          font-size: ${rem(36)};
          line-height: ${rem(40)};
          font-weight: 700;
        }

        &.small {
          font-size: ${rem(12)};
          line-height: ${rem(16)};
        }

        &.attribution {
          font-weight: 300;
          font-size: ${rem(10)};
          line-height: ${rem(12)};
        }

        &.last {
          font-weight: 300;
          border: none !important;
          font-size: ${rem(12)};
          line-height: ${rem(16)};
        }

        span {
          display: block;
          font-size: ${rem(12)};
          line-height: ${rem(16)};
          padding-left: 16px;
          font-weight: 300;

          &.indent {
            display: inline;
          }

          &.light {
            display: inline;
            padding: 0;
            margin: 0;
          }

          ${media.mobile`
            padding-left: 8px;
          `}
        }
      }

      td + td,
      th {
        text-align: center;
      }
    }

    thead {
      tr {
        &.bottom-line,
        &:last-child {
          td,
          th {
            border-bottom: 18px solid ${Color.ritualBlue};
          }
        }
      }
    }

    tbody {
      tr {
        &:first-child {
          td,
          th {
            font-weight: 500;
          }
        }

        &.bottom-line {
          td,
          th {
            border-bottom: 9px solid ${Color.ritualBlue};
          }
        }

        &:first-child {
          td,
          th {
            border-bottom: 4px solid ${Color.ritualBlue};
          }
        }
      }
    }
  }
`;

const SupplementFacts = ({ supplementFacts, isOpen, onRequestClose }) => {
  function renderTable(supplementFacts) {
    if (!supplementFacts) return null;

    return generateTable(supplementFacts);
  }

  const generateTable = (data) => {
    const hasSecondaryDV = !!data?.labels?.dv2;

    return (
      <table>
        <thead>
          <tr>
            <th aria-label="title" />
            <th aria-label="service size" />
            <th aria-label="servings per container" />
          </tr>
          <tr>
            <th
              colSpan={hasSecondaryDV ? 5 : 3}
              className="title"
              id="table-title"
            >
              <Text
                id="product.supplement-facts.heading"
                defaultMessage="Supplement Facts"
              />
            </th>
          </tr>
          <tr>
            <td colSpan="3">
              <Text
                id="product.supplement-facts.serving-size"
                defaultMessage="Serving Size:"
              />{" "}
              <span className="light">{data.servingSize}</span>
            </td>
          </tr>
          <tr>
            <td colSpan={hasSecondaryDV ? 5 : 3}>
              <Text
                id="product.supplement-facts.serving-pr-con"
                defaultMessage="Servings Per Container:"
              />{" "}
              <span className="light">{data.servingsPerContainer}</span>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr className="outline">
            {hasSecondaryDV ? (
              <>
                <th className={hasSecondaryDV ? "text-right" : ""}>
                  <Text
                    id="product.supplement-facts.amount-per-serve"
                    defaultMessage="Amount Per Serving"
                  />
                </th>
                <th>
                  <Text
                    id="product.supplement-facts.capsules"
                    defaultMessage="{n} Capsules"
                    values={{ n: 2 }}
                  />
                </th>
                <th>
                  <Text
                    id="product.supplement-facts.dv"
                    defaultMessage="% DV"
                  />
                </th>
                <th>
                  <Text
                    id="product.supplement-facts.capsules"
                    defaultMessage="{n} Capsules"
                    values={{ n: 4 }}
                  />
                </th>
                <th>
                  <Text
                    id="product.supplement-facts.dv"
                    defaultMessage="% DV"
                  />
                </th>
              </>
            ) : (
              <>
                <td />
                <th>
                  <Text
                    id="product.supplement-facts.amount-per-serve"
                    defaultMessage="Amount Per Serving"
                  />
                </th>
                <th>
                  {data?.labels?.dv || (
                    <Text
                      id="product.supplement-facts.dv"
                      defaultMessage="% DV"
                    />
                  )}
                </th>
              </>
            )}
          </tr>
          {
            // Generate Nutrients
            generateIngredientRow(data.nutrients, "nutrients", hasSecondaryDV)
          }
          {
            // Generate Ingredients
            generateIngredientRow(
              data.ingredients,
              "ingredients",
              hasSecondaryDV,
            )
          }
          {
            // Generate Notations
            data.notations &&
              data.notations.map((notation, i, array) => {
                let className = "outline";
                if (i + 1 === array.length) {
                  className += " no-top";
                }
                if (array.length > 1 && i + 1 < array.length) {
                  className += " no-bottom";
                }
                return (
                  <tr key={`notation-${i}`} className={className}>
                    <td className="small" colSpan="3">
                      {notation.label}
                    </td>
                  </tr>
                );
              })
          }
          {data.otherIngredients && (
            <tr>
              <td colSpan="3">
                <Text
                  id="product.supplement-facts.other-ingredients"
                  defaultMessage="Other ingredients:"
                />{" "}
                <span className="light">{data.otherIngredients}</span>
              </td>
            </tr>
          )}
          {data.allergens &&
            data.allergens.map((allergen, i) => {
              return (
                <tr key={`allergen-${i}`}>
                  <td colSpan="3">
                    <Text
                      id="product.supplement-facts.allergens"
                      defaultMessage={allergen.label}
                      values={{ label: allergen.label }}
                    />
                    {allergen.superset && <sup>{allergen.superset}</sup>}
                    <br></br>
                    {allergen.superset && <sup>{allergen.superset}</sup>}
                    <span className="light">{allergen.value}</span>
                  </td>
                  {""}
                </tr>
              );
            })}
          {data.notationReferences && (
            <tr>
              <td
                className="last"
                colSpan="3"
                dangerouslySetInnerHTML={{ __html: data.notationReferences }}
              />
            </tr>
          )}
          {
            // Generate Notations
            data.attributions &&
              data.attributions.map((attribution, i, array) => {
                return (
                  <tr key={`attribution-${i}`}>
                    <td className="attribution" colSpan="3">
                      {attribution.superset && (
                        <sup>{attribution.superset}</sup>
                      )}
                      {attribution.label}
                    </td>
                  </tr>
                );
              })
          }
          {
            // Additional Information
            data.additionalInformation &&
              data.additionalInformation.map((info, i) => {
                if (!info || !info.label) return null;
                return (
                  <Fragment key={`additional-${i}`}>
                    <tr>
                      <td></td>
                    </tr>
                    <tr className="outline">
                      <td>{info.label}</td>
                      <td>{info.value}</td>
                    </tr>
                    {info.description && (
                      <tr className="outline">
                        <td>{info.description}</td>
                      </tr>
                    )}
                  </Fragment>
                );
              })
          }
        </tbody>
      </table>
    );
  };

  const generateIngredientRow = (data, key, hasSecondaryDV) => {
    if (!data) return null;

    return data.map((item, i, array) => {
      let className = "outline";
      if (i + 1 === array.length) {
        className += " bottom-line";
      }
      return (
        <tr key={`${key}-${i}`} className={className} data-test-ingredient-row>
          <td
            dangerouslySetInnerHTML={{ __html: item.label }}
            data-testid={`ingredient-label-${i}`}
          />
          <td dangerouslySetInnerHTML={{ __html: item.dosage }} />
          <td dangerouslySetInnerHTML={{ __html: item.dv }} />
          {hasSecondaryDV && (
            <>
              <td dangerouslySetInnerHTML={{ __html: item?.dosage2 }} />
              <td dangerouslySetInnerHTML={{ __html: item?.dv2 }} />
            </>
          )}
        </tr>
      );
    });
  };

  return (
    <>
      <MagicModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentStyle={contentStyle}
        mobileFullPage={true}
      >
        <TableWrapper>
          <div>{renderTable(supplementFacts)}</div>
        </TableWrapper>
      </MagicModal>
    </>
  );
};

export default SupplementFacts;
