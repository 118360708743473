import styled from "styled-components";
import intlService from "../../services/intl";

// Utils
import { Color, Opacity, rem, responsive, rgba } from "../../utils/style";
import { Icons } from "../../utils/react-svg";

// Styled Elements
const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;

  margin: 16px 0;

  & > p {
    margin: 0;
    text-transform: uppercase;
    font-size: ${rem(12)};
    line-height: ${rem(18)};
    font-weight: 400;
    color: ${rgba(Color.ritualBlue, Opacity.light40)};
    max-width: 223px;

    ${responsive.md`
      font-size: ${rem(14)};
      line-height: ${rem(20)};
      max-width: 263px;
    `};

    span {
      display: block;
      color: ${Color.ritualBlue};
    }
  }
`;

const CFULogo = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 16px 0 0;
  flex: 0 0 auto;

  ${responsive.md`
    width: 59px;
    height: 59px;
  `};

  svg {
    width: 100%;
    height: 100%;
  }
`;

export default function ProbioticBlend() {
  return (
    <Wrapper>
      <CFULogo>
        <Icons.IconCfu />
      </CFULogo>
      <p
        dangerouslySetInnerHTML={{
          __html: intlService.t(
            "product.gut-health.probiotic-blend",
            "<span>Probiotic blend</span> from two of the world's most clinically-studied strains",
          ),
        }}
      />
    </Wrapper>
  );
}
