import styled from "styled-components";

// Utils
import { Font, Color, rem, responsive } from "../../../utils/style";

// Redux
import { useSelector } from "react-redux";
import staticSelectors from "../../../store/staticData/selectors";

const Name = styled.h1`
  ${Font.circular}
  color: ${Color.ritualBlue};
  margin: 0;
  font-weight: 500;
  font-size: ${rem(26)};
  line-height: ${rem(36)};
  text-align: left;
  letter-spacing: -0.3px;

  ${responsive.md`
    font-size: ${rem(34)};
    line-height: ${rem(40)};
    letter-spacing: -0.5px;
  `}

  em {
    ${Font.dutch}
    font-size: ${rem(20)};
    line-height: ${rem(30)};
    font-style: italic;
    display: block;

    p {
      margin: 0;
    }

    ${responsive.md`
      font-size: ${rem(26)};
      line-height: ${rem(36)};
      letter-spacing: -0.3px;
    `}
  }
`;

const ProductName = (props) => {
  const { product } = props;

  const contentfulProduct = useSelector(
    staticSelectors.contentfulProducts,
  ).find((cp) => cp.sku === product.sku);

  return (
    <Name>
      <em>
        <p>{contentfulProduct.productSubhead}</p>
      </em>
      <span>{product.summary}</span>
    </Name>
  );
};

export default ProductName;
