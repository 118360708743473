import { Component } from "react";
import styled from "styled-components";
import { media, rem, Font, responsive, Opacity } from "../../utils/style";
import ReviewStars from "./ReviewStars";
import sanitizeHtml from "isomorphic-html-sanitize";
import { Icons } from "../../utils/react-svg";
import Text from "../Text";
import intl from "../../services/intl";

const ReviewTitle = styled.h2`
  padding: 16px 0 0 40px;
  font-size: ${rem(22)};
  font-weight: 500;
  margin: 0;

  ${media.tablet`
    padding-left: ${rem(24)};
    font-size: ${rem(18)};
  `};
`;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const StarWrapper = styled.div.attrs({
  className: "d-flex",
})`
  padding: 0 40px;

  .ReviewStars--star {
    width: 17px;
    height: 16px;
    margin-right: 0;

    ${media.tablet`
      width: 17px;
      height: 16px;
    `};
  }

  ${media.tablet`
    padding-left: 24px;
  `};
`;

const ReviewComment = styled.div.attrs({
  className: "mb-4",
})`
  ${(p) => (p.helpful ? "padding: 8px 24px;" : "padding: 8px 24px 0;")};

  ${responsive.sm`
    padding: 16px 40px;
  `}

  h2 {
    font-size: ${rem(16)};
    line-height: ${rem(26)};
  }
`;

const ReviewCommentContent = styled.p.attrs({
  className: "m-0",
})`
  ${Font.dutch};
  font-size: ${rem(16)};
  line-height: ${rem(26)};
`;

const TitleContainer = styled.p`
  ${Font.circular}
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  font-weight: 500;
  color: rgba(20, 43, 111, ${Opacity.light});
  padding: 40px 40px 24px;
  margin: 0;

  ${media.tablet`
    padding: 24px;
  `}
`;

const ReadMoreButton = styled.button.attrs({
  className: "d-flex align-items-center m-0 p-0 mt-3 mt-md-4",
})`
  background: none;
  border: none;
  color: #142b6f;
  font-size: ${rem(14)};
  font-weight: 500;
  transition: opacity 200ms ease-in-out;

  &:hover {
    cursor: pointer;
    opacity: ${Opacity.light};
  }

  .icon-down {
    display: block;
    position: relative;
    width: 12px;
    height: 7px;
    margin-left: 6px;

    svg {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
`;

export default class HighlightedReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayLongContent: false,
    };
  }

  handleReadMoreClick() {
    this.setState({ displayLongContent: true });
  }

  renderComment() {
    const { displayLongContent } = this.state;
    let { content, helpful } = this.props;

    content = sanitizeHtml(content, { allowedTags: [] });

    const shouldTruncate = !displayLongContent && content.length > 250;

    if (shouldTruncate) {
      content = content.slice(0, 250).concat("&#8230; ");
    }

    const shouldDisplayReadMoreButton = !displayLongContent && shouldTruncate;

    return (
      <ReviewComment helpful={helpful}>
        <ReviewCommentContent dangerouslySetInnerHTML={{ __html: content }} />
        {shouldDisplayReadMoreButton && (
          <ReadMoreButton onClick={this.handleReadMoreClick.bind(this)}>
            <Text id="general.read-more" defaultMessage="Read More" />
            <span className="icon-down">
              <Icons.CaretDown />
            </span>
          </ReadMoreButton>
        )}
      </ReviewComment>
    );
  }

  render() {
    let { score, title, helpful } = this.props;

    title = sanitizeHtml(title, { allowedTags: [] });

    const reviewTitle = helpful
      ? intl.t("reviews.highighted.title-helpful", "Making Us Blush")
      : intl.t("reviews.highighted.title-not-helpful", "Keeping It Real");

    return (
      <Wrapper>
        <TitleContainer>{reviewTitle}</TitleContainer>
        <StarWrapper className={"star-wrapper"}>
          <ReviewStars score={score} />
        </StarWrapper>
        <ReviewTitle dangerouslySetInnerHTML={{ __html: title }} />
        {this.renderComment()}
      </Wrapper>
    );
  }
}
