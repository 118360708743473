import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import intl from "../../../services/intl";
import { updateFooterUSPNotice } from "../../../store/navigation/actions";
import metrics from "../../../utils/metrics";
import NameMap from "../../../utils/nameMap";
import { ProductSKU, getEssentialHeadline } from "../../../utils/product";
import { Opacity, responsive } from "../../../utils/style";
import ScrollMagicController from "../../ScrollMagicController";
import CircleNew from "../../home/Circle.New";
import FeaturedArticles from "../../home/FeaturedArticles";
import Press from "../../home/Press";
import DownloadGuide from "../../product/DownloadGuide";
import IngredientsOverview from "../../product/IngredientsOverview";
import AggregatedStarRating from "../../reviews/AggregatedStarRating";
import MostHelpfulReviews from "../../reviews/MostHelpfulReviews";
import Testimonials from "../../testimonials/Testimonials";
import Experts from "../Experts";
import PillSection from "../PillSection";
import ProductHero from "../ProductHero";
import QAOverview from "../QAOverview";
import Reviews from "../Reviews";
import ScrollAnimation from "../ScrollAnimation";
import Timeline from "../Timeline";
import ValuePropsNew from "../ValueProps.New";
import HowToUse from "../protein/HowToUse";
import ProteinIngredientsOverview from "../protein/ProteinIngredientsOverview";

// Styled Elements
const ValuePropsWrapper = styled.section.attrs({
  "aria-label": "Product Value Propositions",
})`
  margin-bottom: 40px;

  ${responsive.md`
    margin-bottom: 56px;
  `}

  .value-props {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    ${responsive.lg`
      flex: 0 0 89.232%;
      max-width: 89.232%;
      margin: auto;
      padding-right: 0;
      padding-left: 0;
    `}
  }
`;

const Spacer = styled.div`
  height: 40px;

  ${responsive.md`
    height: 80px;
  `}

  ${responsive.lg`
    height: 120px;
  `}
`;

const TestimonialsWrapper = styled.div`
  margin-top: 56px;
  margin-bottom: 56px;
`;

const DefaultModuleOrder = [
  "benefits",
  "design-tour",
  "ingredients",
  "how-to-use",
  "pill",
];
const SingleIngredientOrder = ["benefits", "how-to-use", "pill", "ingredients"];

const ProductDetailPage = (props) => {
  const [moduleOrder, setModuleOrder] = useState(DefaultModuleOrder);
  const [scrollRef, setScrollRef] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateFooterUSPNotice(props.productAttributes.sku === "EFW60"));

    if (
      props.productAttributes.sku === "CHLC" ||
      props.productAttributes.sku === "OM3C"
    ) {
      setModuleOrder(SingleIngredientOrder);
    }
  }, [setModuleOrder]);

  function trackProductCTAClick(e) {
    let event = {
      title: e.target.innerText,
      location: "Hero",
    };
    metrics.track("CTA Clicked", event);
  }

  function trackCTAClick(title, location, url, e) {
    e.preventDefault();

    metrics.track(
      "CTA Clicked",
      {
        title: title,
        location: location,
      },
      {},
      () => {
        window.location.href = url;
      },
    );
  }

  const {
    data,
    product,
    pageContext,
    quantity,
    updateQuantity = undefined,
    selectedProduct,
    handleSelectedProduct = undefined,
    reviewRatingsMap,
    reviewCountsMap,
  } = props;
  const { productId } = pageContext;
  const { mostHelpful, mostCritical, contentfulClinical, contentfulProduct } =
    data;

  const {
    sku,
    heroBackgroundColor,
    heroFeaturedImages,
    heroZoomImages,
    valueProps,
    timelineHeading,
    timelineSubhead,
    timelinePhoto,
    timelineVideo,
    timelineData,
    ingredients,
    ingredientsSubheader,
    headerDescription,
    faq,
    articles,
    experts,
    pressQuotes,
    slug,
    simpleName = NameMap(product).plain,
    designTour,
    doctorGuide,
    somethingMissingTitle,
    pillSectionTitle,
    pillSectionImage,
    pillSectionAccordion,
    productStudyModal,
    showReviews,
  } = contentfulProduct;

  const heroContent = {
    quantity,
    featuredImages: heroFeaturedImages,
    zoomImages: heroZoomImages,
    heroBackgroundColor,
  };

  // Subcategory labels
  const subCategorySelector = {
    isMultivitamin: product.subCategory === "Multivitamin",
    isProtein: product.subCategory === "Protein",
    isImmunity: product.subCategory === "Immune Support",
    isGutHealth: product.subCategory === "Gut Health",
    isSkin: product.subCategory === "Skin",
    isSleep: product.subCategory === "Sleep",
    isSingleIngredient: product.subCategory === "Single Ingredient",
  };

  const timeline = JSON.parse(timelineData.childMarkdownRemark.rawMarkdownBody);
  // Articles & Press
  const showFeaturedArticles = articles && articles.length;
  const showExperts = experts && experts.length;
  const showPressQuotes = pressQuotes && pressQuotes.length;
  // Reviews
  const productUrl = `${process.env.GATSBY_URL}/products/${slug}`;
  const contentfulReviews = data.allProductReview.edges.map((e) => e.node);
  const productRating = {
    reviewScore: reviewRatingsMap[productId],
    reviewCount: reviewCountsMap[productId],
  };

  const essentialHeadline = getEssentialHeadline(product);
  const showHowToUse = subCategorySelector.isProtein;
  const showProductDesignSection =
    subCategorySelector.isProtein ||
    subCategorySelector.isMultivitamin ||
    subCategorySelector.isSingleIngredient; // Choline and Omega3
  const showDoctorsGuide = subCategorySelector.isMultivitamin && doctorGuide;
  const guide = doctorGuide && {
    guideText: intl.t(
      "templates.product.guide-text",
      `Ready to talk to your doctor about {product}?`,
      { product: contentfulProduct.name.name },
    ),
    doctorGuide,
  };
  const showTestimonials =
    subCategorySelector.isMultivitamin && sku.includes("EFW");
  const additionalQAMargin = !showTestimonials && !doctorGuide;

  const obgynSkus = [ProductSKU.EPRE60, ProductSKU.EPRE60M];
  const showObgynClaim = obgynSkus.includes(sku);

  return (
    <>
      <ProductHero
        {...heroContent}
        {...productRating}
        product={contentfulProduct}
        productData={props.product}
        productStudyModal={productStudyModal}
        selectedProduct={selectedProduct} // used for scent selection
        trackCTAClick={trackProductCTAClick}
        handleSelectedProduct={handleSelectedProduct}
        updateQuantity={updateQuantity}
        headerDescription={headerDescription}
        showCta={true}
        showActionEyebrow={true}
        showQuantityPicker={subCategorySelector.isProtein}
        clinicalData={contentfulClinical}
        showProbioticBlend={subCategorySelector.isGutHealth}
        showGutModelStudy={subCategorySelector.isGutHealth}
        showInformedSportModal={subCategorySelector.isProtein}
        showExtendedReleaseModal={subCategorySelector.isImmunity}
        showObgynClaim={showObgynClaim}
      />

      <ValuePropsWrapper>
        <ValuePropsNew className={"value-props"} valueProps={valueProps} />
      </ValuePropsWrapper>

      {moduleOrder.map((module) => {
        if (module === "benefits" && timeline) {
          return (
            <Fragment key={module}>
              <Timeline
                timelineHeading={timelineHeading}
                timelineSubhead={timelineSubhead}
                image={timelinePhoto}
                video={timelineVideo}
                data={timeline}
                controller={scrollRef}
              />

              <ScrollMagicController
                ref={(r) => {
                  r && setScrollRef(r.controller);
                }}
              />
            </Fragment>
          );
        }

        if (module === "design-tour" && designTour) {
          return (
            <ScrollAnimation
              key={module}
              controller={scrollRef}
              data={designTour}
            />
          );
        }

        if (module === "ingredients" && ingredients) {
          return subCategorySelector.isMultivitamin ? (
            <IngredientsOverview
              key={module}
              product={contentfulProduct}
              ingredients={ingredients}
              ingredientsSubheader={ingredientsSubheader}
              somethingMissingTitle={somethingMissingTitle}
            />
          ) : (
            <ProteinIngredientsOverview
              key={module}
              product={contentfulProduct}
              productType={sku}
              ingredients={ingredients}
              ingredientsSubheader={ingredientsSubheader}
              showQualityOverQuantity={subCategorySelector.isProtein}
              showOtherIngredients={subCategorySelector.isProtein}
            />
          );
        }

        if (module === "how-to-use" && showHowToUse) {
          return <HowToUse key={module} productType={essentialHeadline} />;
        }

        if (module === "pill" && showProductDesignSection) {
          return (
            <PillSection
              key={module}
              pillSectionTitle={pillSectionTitle}
              pillSectionImage={pillSectionImage}
              pillSectionAccordion={pillSectionAccordion}
            />
          );
        }
      })}

      <QAOverview questions={faq} additionalMargin={additionalQAMargin} />

      {showDoctorsGuide && (
        <DownloadGuide trackCTAClick={trackCTAClick} {...guide} />
      )}

      {showTestimonials && (
        <TestimonialsWrapper>
          <Testimonials />
        </TestimonialsWrapper>
      )}

      {showFeaturedArticles && (
        <FeaturedArticles
          productSku={sku}
          articles={articles.slice(0, 2)}
          className="mt-5 mt-md-7"
        >
          <CircleNew className="bottom right featuredArticles" />
        </FeaturedArticles>
      )}

      {showExperts && (
        <Experts arrowsEnabled={true} experts={experts}>
          <CircleNew className="top right experts" />
        </Experts>
      )}
      {showPressQuotes ? (
        <Press
          quotes={pressQuotes}
          background={`rgba(233,238,241,${Opacity.light})`}
        />
      ) : (
        <Spacer />
      )}

      {showReviews &&
        !!productRating?.reviewScore &&
        !!contentfulReviews?.length && (
          <Reviews
            reviews={contentfulReviews}
            productUrl={productUrl}
            productName={simpleName}
            productId={productId}
            seeAllReviewsCta={true}
            isProductPage={true}
            {...productRating}
          >
            <AggregatedStarRating
              score={productRating.reviewScore}
              totalReviews={productRating.reviewCount}
              productId={productId}
            />
            {mostHelpful && mostCritical && (
              <MostHelpfulReviews
                mostHelpful={mostHelpful}
                mostCritical={mostCritical}
              />
            )}
          </Reviews>
        )}
    </>
  );
};

export default ProductDetailPage;
