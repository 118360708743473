import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useState } from "react";
import styled from "styled-components";

// Utils
import findDosageByProduct from "../../../utils/findDosageByProduct";
import { addDisclaimerLink } from "../../../utils/markdown";
import { Icons } from "../../../utils/react-svg";
import { Color, Font, Opacity, rem, responsive } from "../../../utils/style";

// Components
import Row from "../../Row";
import Text from "../../Text";

const CaretDownElement = Icons["CaretDown"];

const IngredientArea = styled.div.attrs({
  className: "container-fluid",
})`
  margin-bottom: 40px;

  ${responsive.md`
    margin-bottom: 80px;
  `}
  &.protein-ingredient-card-right > .row {
    ${responsive.sm`
      flex-direction: row-reverse;

      .protein-ingredients-card-image-col {
        padding-right: 0;
        padding-left: 15px;
      }

      .protein-ingredients-card-info-col {
        padding-left: 0;
        padding-right: 15px;

        > div:first-of-type {
          margin-right: auto;
          margin-left: 0;
        }
      }
    `}

    ${responsive.lg`
      .protein-ingredients-card-image-col {
        display: flex;
        justify-content: flex-start;
      }
    `}
  }
`;

const InfoContainer = styled.div`
  width: 100%;

  ${responsive.sm`
    max-width: 280px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    height: 100%;
    width: 100%;
  `}
  ${responsive.md`
    justify-content: center;
    max-width: 374px;
  `}
  ${responsive.lg`
    max-width: 470px;
  `}
  &.details-open {
    h3 {
      border-bottom: 0;
    }

    svg {
      transform: rotate(180deg);
    }

    dl {
      margin-top: 0;
      display: flex;
    }
  }

  p {
    span {
      display: block;
      opacity: ${Opacity.light};
    }
  }
`;

const IngredientsInfoCol = styled.div.attrs({
  className: "col-sm-6",
})`
  ${responsive.sm`
    padding-right: 0;
    padding-left: 15px;
  `};

  @media (min-width: 576px) {
    width: 100%;
  }

  @media (min-width: 750px) {
    max-width: calc(700px / 2);
  }

  @media (min-width: 960px) {
    max-width: calc(934px / 2);
  }

  @media (min-width: 1200px) {
    max-width: calc(1170px / 2);
  }

  h2 {
    font-size: ${rem(30)};
    font-weight: 500;
    letter-spacing: -0.4px;
    line-height: ${rem(36)};
    margin-bottom: 8px;

    ${responsive.md`
      margin-bottom: 24px;
      font-size: ${rem(40)};
      letter-spacing: -0.6px;
      line-height: ${rem(46)};
    `}

    em {
      ${Font.dutch};
    }
  }

  p {
    ${Font.dutch};
    font-size: ${rem(16)};
    font-weight: normal;
    letter-spacing: 0;
    line-height: ${rem(26)};
    padding-bottom: 24px;
    border-bottom: 2px solid ${Color.ritualBlue};

    ${responsive.sm`
      padding-bottom: 24px;
    `}

    ${responsive.md`
      font-size: ${rem(18)};

      margin-bottom: 24px;
      line-height: ${rem(28)};
    `}
  }

  h3 {
    padding: 0 0 16px 0;
    margin: 0;
    font-size: ${rem(16)};
    font-weight: 500;
    letter-spacing: 1px;
    line-height: ${rem(26)};
    text-transform: uppercase;
    border-bottom: 1px solid ${Color.fadedGrey};
    display: flex;
    align-items: center;

    ${responsive.md`
      display: none;
    `}
    svg {
      height: 8px !important;
      margin-left: auto;
    }
  }
`;

const IngredientsImageCol = styled.div.attrs({
  className: "col-sm-6",
})`
  margin-bottom: 24px;
  width: 100%;

  ${responsive.sm`
    margin-bottom: 0;
    padding-left: 0;
  `}

  ${responsive.md`
    display: flex;
    justify-content: flex-end;
  `}
`;

const IngredientDescriptionList = styled.dl`
  width: 100%;
  flex-wrap: wrap;
  line-height: ${rem(22)};
  ${Font.circular};
  display: none;
  margin-top: 24px;
  margin-bottom: 0;
  border-bottom: 1px solid ${Color.fadedGrey};

  > * {
    margin-bottom: 16px;
  }

  ${responsive.md`
    margin-top: 0;
    display: flex;
  `}
`;

const DetailsToggleButton = styled.button`
  width: 100%;
  background: none;
  color: inherit;
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  line-height: ${rem(22)};
  letter-spacing: 1px;
  font-size: ${rem(16)};
  padding-top: 16px;

  ${responsive.sm`
    padding-top: unset;
  `}
`;

const IngredientTerm = styled.dt`
  font-weight: 300;
  color: ${Color.lessFadedBlue};
  width: 100%;
  margin-bottom: 4px;

  ${responsive.md`
    margin-bottom: 16px;
    width: 40%;
    padding-right: 32px;

    &:last-of-type {
      margin-bottom: 24px;
    }
  `}

  ${responsive.lg`
    width: calc(1 / 3 * 100%);
  `}
`;

const IngredientDetail = styled.dd`
  color: ${Color.ritualBlue};
  width: 100%;
  font-weight: 300;

  &:last-child {
    margin-bottom: 24px;
  }

  ${responsive.md`
    width: 60%;
  `}

  ${responsive.lg`
    width: calc(2 / 3 * 100%);
  `}
`;

export default function ProteinIngredientsCard({
  ingredient,
  index,
  handleClick,
  product,
}) {
  const [areDetailsOpen, setAreDetailsOpen] = useState(false);
  const { image } = ingredient;
  const ingredientDetailsId = `ingredients-details-${index}`;
  const ingredientDetailsToggleId = `ingredients-details-toggle-${index}`;
  const dosage = findDosageByProduct(ingredient, product);

  return (
    <IngredientArea
      className={index % 2 === 0 ? "" : "protein-ingredient-card-right"}
      onClick={() => handleClick(ingredient)}
    >
      <Row>
        <IngredientsImageCol className={"protein-ingredients-card-image-col"}>
          <GatsbyImage
            image={getImage(image)}
            alt={ingredient.cardImage.title || "essential ingredient"}
            style={{
              userSelect: "none",
              userDrag: "none",
              pointerEvents: "none",
              touchCallout: "none",
              width: "100%",
              maxWidth: "720px",
              backgroundColor: Color.ritualGrey,
            }}
          />
        </IngredientsImageCol>
        <IngredientsInfoCol className={"protein-ingredients-card-info-col"}>
          <InfoContainer className={areDetailsOpen ? "details-open" : ""}>
            <h2>
              {ingredient.name} {dosage && <em>{dosage}</em>}
            </h2>
            <p
              dangerouslySetInnerHTML={{
                __html: addDisclaimerLink(ingredient.description),
              }}
            />
            <h3>
              <DetailsToggleButton
                aria-expanded={areDetailsOpen}
                aria-controls={ingredientDetailsId}
                id={ingredientDetailsToggleId}
                onClick={() => {
                  setAreDetailsOpen(!areDetailsOpen);
                }}
              >
                <Text
                  id="product.protein.ingredient-card.details"
                  defaultMessage="Details"
                />
                <CaretDownElement />
              </DetailsToggleButton>
            </h3>
            <IngredientDescriptionList
              id={ingredientDetailsId}
              aria-labelledby={ingredientDetailsToggleId}
            >
              {ingredient.officialName && (
                <>
                  <IngredientTerm>
                    <Text
                      id="product.protein.ingredient-card.name"
                      defaultMessage="Name"
                    />
                  </IngredientTerm>
                  <IngredientDetail>{ingredient.officialName}</IngredientDetail>
                </>
              )}
              {ingredient.source && (
                <>
                  <IngredientTerm>
                    <Text
                      id="product.protein.ingredient-card.source"
                      defaultMessage="Source"
                    />
                  </IngredientTerm>
                  <IngredientDetail>{ingredient.source}</IngredientDetail>
                </>
              )}
              {ingredient.supplier && (
                <>
                  <IngredientTerm>
                    <Text
                      id="product.protein.ingredient-card.supplier"
                      defaultMessage="Supplier"
                    />
                  </IngredientTerm>
                  <IngredientDetail>{ingredient.supplier}</IngredientDetail>
                </>
              )}
              {ingredient.manufacturingLocation && (
                <>
                  <IngredientTerm>
                    <Text
                      id="product.protein.ingredient-card.finalLocationOfManufacturing"
                      defaultMessage="Final Location of Manufacturing"
                    />
                  </IngredientTerm>
                  <IngredientDetail>
                    {ingredient.manufacturingLocation}
                  </IngredientDetail>
                </>
              )}
            </IngredientDescriptionList>
          </InfoContainer>
        </IngredientsInfoCol>
      </Row>
    </IngredientArea>
  );
}
