import { useState } from "react";
import styled, { css } from "styled-components";
import { CSSTransition, SwitchTransition } from "react-transition-group";

// Utils
import { Color, responsive, Font, rem, Opacity } from "../../../utils/style";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import MagicModal from "../../MagicModal";
import { Icons } from "../../../utils/react-svg";

const MODAL_IMAGE_HEIGHT_XSM = "230px";
const MODAL_IMAGE_HEIGHT_SM_PLUS = "279px";
const MODAL_PADDING_TOP = "40px";

const MODAL_TRANSITION_CONTENT_TIMING = 500;
const ModalContents = styled.section`
  padding-top: calc(${MODAL_IMAGE_HEIGHT_XSM} - ${MODAL_PADDING_TOP});
  display: flex;
  flex-direction: column;
  height: 100%;

  .protein-story-animation-enter {
    opacity: 0.01;
  }

  .protein-story-animation-enter.protein-story-animation-enter-active {
    opacity: 1;
    transition: opacity ${MODAL_TRANSITION_CONTENT_TIMING}ms ease-in;
  }

  .protein-story-animation-exit {
    opacity: 1;
  }

  .protein-story-animation-exit.protein-story-animation-exit-active {
    opacity: 0.01;
    transition: opacity ${MODAL_TRANSITION_CONTENT_TIMING}ms ease-in;
  }

  ${responsive.sm`
      padding-top: calc(${MODAL_IMAGE_HEIGHT_SM_PLUS} - ${MODAL_PADDING_TOP});
  `}
  > div:first-child {
    height: ${MODAL_IMAGE_HEIGHT_XSM};
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;

    ${responsive.sm`
        height: 279px;
        height: ${MODAL_IMAGE_HEIGHT_SM_PLUS};
    `}
  }

  h2 {
    color: ${Color.lessFadedBlue};
    font-size: ${rem(12)};
    height: 20px;
    letter-spacing: 0.8px;
    line-height: ${rem(20)};
    text-transform: uppercase;
    margin: 24px 0 4px;

    ${responsive.sm`
      font-size: ${rem(16)};
      letter-spacing: 1px;
    `}
  }

  h3 {
    font-size: ${rem(22)};
    letter-spacing: -0.2px;
    line-height: ${rem(32)};
    margin: 0 0 16px;

    ${responsive.sm`
      font-size: ${rem(26)};
      line-height: ${rem(36)};
      letter-spacing: -0.3px;
    `}
  }

  p {
    ${Font.dutch};
    font-size: ${rem(16)};
    letter-spacing: 0;
    line-height: ${rem(26)};

    ${responsive.sm`
      font-size: ${rem(18)};
      line-height: ${rem(28)};
    `}
  }

  .modal-nav {
    height: 100%;
    display: flex;
    align-items: flex-end;

    span {
      font-size: ${rem(16)};
      font-weight: 500;
      letter-spacing: 1px;
      line-height: ${rem(26)};
      text-transform: uppercase;
      margin-right: auto;
    }

    button {
      background: none;
      color: inherit;
      padding: 0;
      border: none;
      margin-left: 22px;

      &:first-child {
        margin: 0 22px 0 0;
      }

      &[disabled] {
        svg {
          opacity: 0.24;
        }
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          opacity: ${Opacity.light};
        }
      }

      svg {
        width: 26px;
        height: 16px;

        g {
          stroke-width: 1.5px;
        }
      }
    }
  }
`;

const CloseButtonWrapper = styled.div`
  background: #fff;
  border-radius: 50%;
  padding: 0;
  border: 0;
  width: 48px;
  height: 48px;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    position: relative;
    width: initial;
    height: initial;
  }

  &:hover svg {
    opacity: ${Opacity.light};
  }
`;

const ModalStyle = css`
  bottom: 0;
  top: initial;

  ${responsive.sm`
    top: inherit;
    height: 100%;
  `}

  .magic-modal-close-button:hover {
    opacity: 1;
  }
`;

function resetActiveStoryDelayedToPreventFlicker(setActiveStory, props) {
  setTimeout(() => {
    setActiveStory(props.activeStory);
  }, 250);
}

export default function ProteinIngredientStoryModal(props) {
  const [activeStory, setActiveStory] = useState(props.activeStory);
  const activeStoryContent =
    props.ingredient.ingredientStoryContent[activeStory];
  const activeStoryImage = props.ingredient.ingredientStoryImages[activeStory];
  const isNextDisabled =
    activeStory === props.ingredient.ingredientStoryContent.length - 1
      ? "disabled"
      : "";
  const isPreviousDisabled = activeStory === 0 ? "disabled" : "";

  return (
    <MagicModal
      slideOut={true}
      onRequestClose={() => {
        props.onRequestClose();
        resetActiveStoryDelayedToPreventFlicker(setActiveStory, props);
      }}
      isOpen={props.isOpen}
      contentStyle={ModalStyle}
      closeButtonContentOverride={
        <CloseButtonWrapper>
          <Icons.Close />
        </CloseButtonWrapper>
      }
    >
      <ModalContents>
        <SwitchTransition>
          <CSSTransition
            classNames="protein-story-animation"
            timeout={MODAL_TRANSITION_CONTENT_TIMING}
            key={`ingredients-modal-contents-${activeStory}`}
          >
            <>
              <GatsbyImage
                image={getImage(activeStoryImage)}
                alt={activeStoryImage.title}
                style={{
                  userSelect: "none",
                  userDrag: "none",
                  pointerEvents: "none",
                  touchCallout: "none",
                  width: "100%",
                  backgroundColor: Color.ritualGrey,
                }}
              />
              <h2>{props.ingredient.name}</h2>
              <h3>{activeStoryContent.title}</h3>
              <p>{activeStoryContent.description}</p>
            </>
          </CSSTransition>
        </SwitchTransition>
        <div className={"modal-nav"}>
          <span>
            {activeStory + 1} / {props.ingredient.ingredientStoryContent.length}
          </span>
          <button
            aria-hidden="true"
            disabled={isPreviousDisabled}
            type={"button"}
            onClick={() => {
              setActiveStory(activeStory - 1);
            }}
          >
            <Icons.ArrowRoundedLeft />
          </button>
          <button
            aria-hidden="true"
            type={"button"}
            disabled={isNextDisabled}
            onClick={() => {
              setActiveStory(activeStory + 1);
            }}
          >
            <Icons.ArrowRoundedRight />
          </button>
        </div>
      </ModalContents>
    </MagicModal>
  );
}
