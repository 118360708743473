import styled from "styled-components";

import intl from "../../services/intl";
import { getPlanForProductSku } from "../../utils/planToProduct";
import { responsive } from "../../utils/style";
import { EssenceVanilla, EssenceVanillaWhite, FlavorCitrus, FlavorCitrusBerry, FlavorMint, FlavorOrange, FlavorVanilla } from "../../utils/svg";
import { MagicSVGImage } from "../MagicSVGImage";

const IconWrapper = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;

  ${responsive.md`
    top: 28px;
    left: 24px;
  `}

  &.bundle-hero {
    top: 16px;
    left: 16px;

    ${responsive.md`
    top: 24px;
    left: 24px;
  `}
  }

  img {
    width: 56px;
    height: 56px;

    ${responsive.md`
      width: 92px;
      height: 92px;
    `}
  }

  &.slide-out-modal {
    z-index: 2;
    top: 12px;
    left: 12px;

    img {
      width: 56px;
      height: 56px;
    }
  }
`;

const FlavorIcon = ({ sku, className }) => {
  const plan = getPlanForProductSku(sku);
  if (!plan || !plan.variantDescription) return null;
  const description = plan.variantDescription.toLowerCase();

  // TO-DO: Look into a a more scalable approach
  // such as having a Contentful field for flavor icons
  // rather than parsing the description

  let icon;
  if (description.includes(intl.t("product.icon.mint", "mint"))) {
    icon = (
      <MagicSVGImage width={92} height={92} alt={"Mint"} src={FlavorMint} />
    );
  } else if (description.includes(intl.t("product.icon.berry", "berry"))) {
    icon = (
      <MagicSVGImage
        width={92}
        height={92}
        alt={"FlavorCitrusBerry"}
        src={FlavorCitrusBerry}
      />
    );
  } else if (description.includes(intl.t("product.icon.citrus", "citrus"))) {
    icon = (
      <MagicSVGImage
        width={92}
        height={92}
        alt={"FlavorCitrus"}
        src={FlavorCitrus}
      />
    );
  } else if (description.includes(intl.t("product.icon.vanilla", "vanilla"))) {
    if (description.includes(intl.t("product.icon.essence", "essence")) && sku !== 'SLPC') {
      icon = (
        <MagicSVGImage
          width={92}
          height={92}
          alt={"EssenceVanilla"}
          src={EssenceVanilla}
        />
      );
    } else if(sku === 'SLPC') {
      icon = (
        <MagicSVGImage
          width={92}
          height={92}
          alt={"EssenceVanillaWhite"}
          src={EssenceVanillaWhite}
        />
      );
    } else {
      icon = (
        <MagicSVGImage
          width={92}
          height={92}
          alt={"FlavorVanilla"}
          src={FlavorVanilla}
        />
      );
    }
  } else if (description.includes(intl.t("product.icon.orange", "orange"))) {
    icon = (
      <MagicSVGImage
        width={92}
        height={92}
        alt={"FlavorOrange"}
        src={FlavorOrange}
      />
    );
  }

  if (!icon) return null;

  return <IconWrapper className={className}>{icon}</IconWrapper>;
};

export default FlavorIcon;
