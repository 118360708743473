import { Component } from "react";
import styled from "styled-components";
import Container from "../Container";
import Row from "../Row";
import HighlightedReview from "./HighlightedReview";
import { media, rem } from "../../utils/style";

const ReviewWrapper = styled.div.attrs({
  className: "col-xs-12 col-sm-6",
})`
  border: 2px solid;
  padding: 0 !important;

  &:first-child {
    border-right: 1px solid;
  }

  ${media.mobile`
    margin: 0 10px;
  `};
`;

const MainContainer = styled(Container).attrs({
  className: "col-xs-12 container",
})`
  font-size: ${rem(22)};
  padding-bottom: 56px;

  .row {
    justify-content: center;
    position: relative;
  }

  .review-wrapper:first-child {
    border-right: 0;
  }

  ${media.tablet`
    padding-bottom: 40px;
  `} ${media.mobile`
    .review-wrapper:first-child {
      border-right: 2px solid;
      border-bottom: 0;
    }
  `};
`;

const VsDiv = styled.div.attrs({
  className: "d-flex align-items-center justify-content-center",
})`
  background-color: white;
  border: 2px solid;
  border-radius: 20px;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(100% - 20px);
  height: 40px;
  width: 40px;
  text-transform: uppercase;
  font-size: ${rem(12)};
  line-height: ${rem(20)};
  letter-spacing: 0.8px;
  z-index: 1;

  ${media.mobile`
    left: calc(50% - 20px);
    top: calc(100% - 20px);
  `};
`;

export default class MostHelpfulReviews extends Component {
  render() {
    const { mostHelpful, mostCritical } = this.props;

    return (
      <MainContainer>
        <Row>
          <ReviewWrapper className="review-wrapper">
            <HighlightedReview {...mostHelpful} helpful={true} />
            <VsDiv>
              <span>vs</span>
            </VsDiv>
          </ReviewWrapper>
          <ReviewWrapper>
            <HighlightedReview {...mostCritical} />
          </ReviewWrapper>
        </Row>
      </MainContainer>
    );
  }
}
