import { useCallback, useEffect, useState } from "react";
import { getBreakpoint } from "../utils/style";
import useThrottledResizeObserver from "./useThrottledResizeObserver";

const useBreakpoint = () => {
  // We do not know the breakpoint during build, so intialize to undefined
  // and update after mounting.
  const [breakpoint, setBreakpoint] =
    useState<ReturnType<typeof getBreakpoint>>();
  useEffect(() => {
    setBreakpoint(getBreakpoint());
  }, []);

  // Only use document.body for resize observer when available (e.g. in browser)
  const resizeObserverRef =
    typeof document !== "undefined" ? document.body : undefined;
  const onResize = useCallback(() => {
    setBreakpoint(getBreakpoint());
  }, []);
  useThrottledResizeObserver({ ref: resizeObserverRef, onResize });

  return breakpoint;
};

export default useBreakpoint;
