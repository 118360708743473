import styled from "styled-components";

// Utils
import { Color, responsive, rem, Font, Opacity } from "../../../utils/style";
import { addDisclaimerLink } from "../../../utils/markdown";

// Components
import Container from "../../Container";
import Row from "../../Row";
import Text from "../../Text";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const QualityOverQuantityWrapper = styled(Container)`
  margin-bottom: 0;
`;

const IngredientDescriptionList = styled.dl`
  width: 100%;
  flex-wrap: wrap;
  line-height: ${rem(22)};
  ${Font.circular};
  display: flex;
  margin: 24px 0;
  border-bottom: 1px solid ${Color.fadedGrey};
`;

const IngredientTerm = styled.dt`
  font-weight: 300;
  color: ${Color.lessFadedBlue};
  width: 100%;
  margin-bottom: 4px;

  ${responsive.md`
    margin-bottom: 16px;
    width: 40%;
    padding-right: 32px;

    &:last-of-type {
      margin-bottom: 24px;
    }
  `}

  ${responsive.lg`
    width: calc(1 / 3 * 100%);
  `}
`;

const IngredientDetail = styled.dd`
  color: ${Color.ritualBlue};
  width: 100%;
  font-weight: 300;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 24px;
  }

  ${responsive.md`
    width: 60%;
  `}

  ${responsive.lg`
    width: calc(2 / 3 * 100%);
  `}
`;

const Column = styled.div.attrs({
  className: "col-12 col-sm",
})`
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }

  ${responsive.sm`
    margin-bottom: 0;
  `}
`;

const ColumnContents = styled.div`
  background-color: rgba(242, 241, 245, ${Opacity.light});
  padding: 24px;
  height: 100%;
  position: relative;
  z-index: 1;
  overflow: hidden;

  ${responsive.md`
    padding: 48px 32px 56px 32px;
  `}

  & > span {
    display: block;
    font-size: ${rem(22)};
    font-weight: 500;
    letter-spacing: -0.2px;
    line-height: ${rem(32)};
    padding-bottom: 24px;
    margin-top: 8px;

    border-bottom: 2px solid ${Color.ritualBlue};

    ${responsive.md`
      font-size: ${rem(26)};
    `}
  }

  p {
    ${Font.dutch};
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    margin-bottom: 16px;

    ${responsive.md`
      font-size: ${rem(18)};
    `}
  }

  & > p {
    margin-bottom: 0;

    span {
      display: block;
      font-size: inherit;
      opacity: ${Opacity.light};
    }
  }
`;

const Img = styled(GatsbyImage)`
  position: absolute !important;
  top: 0;
  right: 0;
  width: 100%;
  user-select: none;
  -webkit-user-drag: none;
  pointer-events: none;
  -webkit-touch-callout: none;
  z-index: -1;
`;

export default function QualityOverQuantity(props) {
  const { ingredients } = props;

  return (
    <QualityOverQuantityWrapper>
      <Row>
        {ingredients.map((ingredient) => (
          <Column key={`ingredient-card-secondary-${ingredient.contentful_id}`}>
            <ColumnContents>
              <Img
                image={getImage(ingredient.cardImage)}
                alt={ingredient.cardImage.title}
              />
              <span>{ingredient.name}</span>
              <IngredientDescriptionList>
                <IngredientTerm>
                  <Text
                    id="product.protein.ingredient-card.source"
                    defaultMessage="Source"
                  />
                </IngredientTerm>
                <IngredientDetail>{ingredient.source}</IngredientDetail>
                <IngredientTerm>
                  <Text
                    id="product.protein.ingredient-card.supplier"
                    defaultMessage="Supplier"
                  />
                </IngredientTerm>
                <IngredientDetail>{ingredient.supplier}</IngredientDetail>
                <IngredientTerm>
                  <Text
                    id="product.protein.ingredient-card.function"
                    defaultMessage="Function"
                  />
                </IngredientTerm>
                <IngredientDetail
                  dangerouslySetInnerHTML={{
                    __html: addDisclaimerLink(ingredient.function.function),
                  }}
                />
                <IngredientTerm>
                  <Text
                    id="product.protein.ingredient-card.finalLocationOfManufacturing"
                    defaultMessage="Final Location of Manufacturing"
                  />
                </IngredientTerm>
                <IngredientDetail>
                  {ingredient.manufacturingLocation}
                </IngredientDetail>
              </IngredientDescriptionList>
              <p
                dangerouslySetInnerHTML={{
                  __html: addDisclaimerLink(ingredient.description),
                }}
              />
            </ColumnContents>
          </Column>
        ))}
      </Row>
    </QualityOverQuantityWrapper>
  );
}
