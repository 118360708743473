import intl from "../../services/intl";

export default function () {
  return [
    {
      value: 1,
      title: intl.t(
        "product.hero.quantity-option",
        "{quantity, plural, =0 {} one {1 Bag } other {{quantity} Bags } }",
        {
          quantity: 1,
        },
      ),
    },
    {
      value: 2,
      title: intl.t("product.hero.quantity-option", null, {
        quantity: 2,
      }),
    },
    {
      value: 3,
      title: intl.t("product.hero.quantity-option", null, {
        quantity: 3,
      }),
    },
    {
      value: 4,
      title: intl.t("product.hero.quantity-option", null, {
        quantity: 4,
      }),
    },
    {
      value: 5,
      title: intl.t("product.hero.quantity-option", null, {
        quantity: 5,
      }),
    },
    {
      value: 6,
      title: intl.t("product.hero.quantity-option", null, {
        quantity: 6,
      }),
    },
  ];
}
