import styled from "styled-components";
import intlService from "../../services/intl";
import { Icons } from "../../utils/react-svg";
import { rem, responsive } from "../../utils/style";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0;
`;

const ObgynIcon = styled(Icons.Obgyn)`
  width: 50px;
  height: 58px;
  margin: 0 12px 0 0;
`;

const Caption = styled.p`
  color: #62719f;
  font-size: ${rem(12)};
  font-weight: 300;
  line-height: ${rem(18)};
  letter-spacing: 0;
  margin: 0;

  & > span {
    display: block;
    color: #142b6f;
    font-size: ${rem(14)};
    font-weight: 500;
    line-height: ${rem(20)};
    letter-spacing: 0;

    ${responsive.md`
      font-size: ${rem(16)};
      line-height: ${rem(24)};
    `};
  }
`;

export default function ObgynClaim() {
  return (
    <Wrapper>
      <ObgynIcon />
      <Caption
        dangerouslySetInnerHTML={{
          __html: intlService.t(
            "product.epre.obgyn-caption",
            "<span>OB/GYN Recommended Formula†</span> †Based on the Ritual formulation reviewed by 150 OB/GYNs.",
          ),
        }}
      />
    </Wrapper>
  );
}
