import styled from "styled-components";

import intl from "../../../services/intl";

// Utils
import { Icons } from "../../../utils/react-svg";
import { Color, rem, responsive } from "../../../utils/style";

// Components
import { ProductSKU } from "../../../utils/product";
import Text from "../../Text";

const Container = styled.div`
  margin-top: 32px;

  ${responsive.sm`
    margin-top: 40px;
  `}
`;

const Heading = styled.h3`
  font-size: ${rem(12)};
  font-weight: 500;
  line-height: ${rem(18)};
  letter-spacing: 0.96px;
  color: ${Color.indigoBlue60};
  text-transform: uppercase;
  margin-bottom: 16px;
`;

const BenefitContainer = styled.div`
  display: flex;
  flex-direction: row;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`;

const IconContainer = styled.div`
  height: 24px;
  width: 24px;
  position: relative;
  display: flex;
  margin-right: 12px;
  flex: 0 0 auto;

  svg {
    color: ${Color.ritualYellow};
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    path,
    circle {
      fill: currentColor;
    }

    &.circle-star {
      g {
        transform: scale(1.045);
      }
    }
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: ${rem(14)};
    line-height: ${rem(20)};
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0;
  }

  p:first-of-type {
    color: ${Color.ritualBlue};
    margin-bottom: 4px;
  }

  p:last-of-type {
    color: ${Color.indigoBlue60};
    font-weight: 300;
  }
`;

const SubscriptionBenefits = (props) => {
  const { product } = props;
  const currentLocale = intl.locale;
  const epreSkus = [ProductSKU.EPRE60, ProductSKU.EPRE60M];
  const isEpre = epreSkus.includes(product.sku);
  const benefits = ["money-back"];

  // Only show FSA in US and CA
  isEpre &&
    ["en-US", "en-CA"].includes(currentLocale) &&
    benefits.push("fsa-hsa");

  const renderBenefits = (benefits) => {
    return benefits.map((benefit, i) => {
      return (
        <BenefitContainer key={i}>
          <IconContainer>
            {benefit === "fsa-hsa" ? (
              <Icons.Goodstuff />
            ) : (
              <Icons.CircleStar className="circle-star" />
            )}
          </IconContainer>
          <TextContainer>
            <p>
              <Text
                id={`product.hero.subscription-benefits.${benefit}.benefit`}
              />
            </p>
            <p>
              <Text
                id={`product.hero.subscription-benefits.${benefit}.description`}
              />
            </p>
          </TextContainer>
        </BenefitContainer>
      );
    });
  };

  return (
    <Container>
      <Heading>
        <Text
          id="product.hero.subscription-benefits.heading"
          defaultMessage={"Good Value(s)"}
        />
      </Heading>
      {renderBenefits(benefits)}
    </Container>
  );
};

export default SubscriptionBenefits;
