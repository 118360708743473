import { useEffect } from "react";
import styled from "styled-components";

// Constants
import { Status } from "../../constants/stockStatus";

// Utils
import { Font, rem, responsive, Opacity } from "../../utils/style";
import { trackProductViewed } from "../../utils/tracking/product";
import { getPlanForProductSku } from "../../utils/planToProduct";

const ProductSelectorWrapper = styled.div`
  margin: 0 0 32px;
  display: flex;

  ${responsive.sm`
    margin: 0 0 32px;
  `};
`;

const ProductButton = styled.button`
  ${Font.circular}
  border-radius: 8px;
  background: #fff;
  border: ${(props) =>
    props.active
      ? "2px solid rgb(20, 43, 111)"
      : `2px solid rgba(20, 43, 111, ${Opacity.light})`};
  color: ${(props) =>
    props.active ? "rgb(20, 43, 111)" : `rgba(20, 43, 111, ${Opacity.light})`};

  height: 40px;
  width: 100%;

  transition: 250ms;
  font-size: ${rem(14)};
  font-weight: 500;
  letter-spacing: 0px;

  ${responsive.sm`
    width: auto;
    padding: 0 24px;
  `}

  ${responsive.md`
    height: 42px;
    font-size: ${rem(16)};
  `}

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  &:not(:last-of-type) {
    margin-right: 16px;
  }
`;

export default function ProductSelector({
  products,
  labels,
  selectedProduct,
  setSelectedProduct,
}) {
  useEffect(() => {
    const index = products.findIndex((product) => product === selectedProduct);
    const plan = getPlanForProductSku(selectedProduct.sku);
    trackProductViewed(plan.id, { position: index + 1 });
  }, []);

  products = products.filter((p) => p.stockStatus === Status.InStock);

  if (products.length <= 1) {
    return null;
  }

  return (
    <ProductSelectorWrapper>
      {products &&
        products.map((product, index) => {
          const label = labels[index];

          return (
            <ProductButton
              key={product.sku}
              active={product.sku === selectedProduct.sku}
              onClick={() => {
                const plan = getPlanForProductSku(product.sku);
                trackProductViewed(plan.id, { position: index + 1 });
                setSelectedProduct(product);
              }}
            >
              {label}
            </ProductButton>
          );
        })}
    </ProductSelectorWrapper>
  );
}
